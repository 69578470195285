import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Type, inject } from '@angular/core';
import moment from 'moment';

import { InvestimentsBase } from '../investiments.base';
import { FixedService } from './fixed.service';
import { FixedCreateComponent } from './create/create.component';
import { FixedModifyComponent } from './modify/modify.component';


@Component({
  selector: 'app-fixed',
  standalone: false,
  templateUrl: './fixed.component.html',
  styleUrl: './fixed.component.scss'
})
export class FixedComponent extends InvestimentsBase<FixedCreateComponent, FixedModifyComponent> implements AfterViewInit {

  override service = inject(FixedService)

  override list : any = {
    columns: [
      'share', 'buy', 'maturity', 'invested', 'profitability', 'expected', 'days', 'action'
    ],
    data: [
      { id: 1, share: '', buy: moment().unix(), invested: 0.00, maturity: moment().unix(), profitability: 0.00, expected: 0.00, days: 0 },
      { id: 2, share: '', buy: moment().unix(), invested: 0.00, maturity: moment().unix(), profitability: 0.00, expected: 0.00, days: 0 },
      { id: 3, share: '', buy: moment().unix(), invested: 0.00, maturity: moment().unix(), profitability: 0.00, expected: 0.00, days: 0 },
      { id: 4, share: '', buy: moment().unix(), invested: 0.00, maturity: moment().unix(), profitability: 0.00, expected: 0.00, days: 0 },
      { id: 5, share: '', buy: moment().unix(), invested: 0.00, maturity: moment().unix(), profitability: 0.00, expected: 0.00, days: 0 },
      { id: 6, share: '', buy: moment().unix(), invested: 0.00, maturity: moment().unix(), profitability: 0.00, expected: 0.00, days: 0 },
    ]  
  }
  
  constructor() {
    super(FixedCreateComponent, FixedModifyComponent)
  }

  async ngAfterViewInit(): Promise<void> {
    console.error('FixedComponent -> ngAfterViewInit')

    console.log("listFixedIncomes: ", this.list.data)
  }
  
  // override async evaluate(r: any):Promise<boolean> {
  //   console.error('FixedComponent -> evaluate', r)

  //   const exists = this.list.data.filter((e:any) => {
  //     return moment.unix(e.date.seconds).format('YYYY-MM-DD') == moment(r.date).format('YYYY-MM-DD') && e.share == r.share && e.amount == r.amount
  //   })[0]

  //   return exists==undefined && r.amount > 0 && r.value > 0
  // }
  
}
