<!-- <mat-sidenav-container> -->
    <!-- <mat-sidenav #rightNav fixedInViewport style="width: 100px;"
      mode="side"  
      opened="true"
      position="end">

      <span style="align-items: flex-start; color: aliceblue; margin: 15px;">Temp...</span>
      
    </mat-sidenav> -->
    <!-- <mat-sidenav-content #rightNavContent style="padding: 0px !important;"> -->
        <mat-card appearance="outlined">
            <mat-card-header>
                <h2>Account</h2>
            </mat-card-header>
            <mat-card-content>
                Name: {{user.name}} <br />
                E-mail: {{user.email}}  <br />
                QR Code: <br />
                <img mat-card-image src="{{ qrcode }}" width="150px" height="150px"><br />
                <mat-icon>subscriptions</mat-icon> QR Code: {{user.token}} 
            </mat-card-content>
        </mat-card>
    <!-- </mat-sidenav-content>
</mat-sidenav-container>  -->
