import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Secret, TOTP } from 'otpauth';
import { NgOtpInputComponent } from 'ng-otp-input';


@Component({
  selector: 'app-totp-validate',
  standalone: false,
  templateUrl: './totp-validate.component.html',
  styleUrl: './totp-validate.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotpValidateComponent implements OnInit, OnDestroy {

  loginTitle: string = "Enter the token";
  email: string = "";
  errorMessage: string = "";

  token!: string;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput!: NgOtpInputComponent; //ng-otp-input
  config = {
    allowNumbersOnly: true,
    length: 6,
    inputStyles: {
      width: '40px',
      height: '50px',
    }
  };

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TotpValidateComponent>) {
    this.email = data.email
  }

  ngOnInit(): void {
    console.error('TotpValidateComponent -> ngOnInit');
  }

  ngOnDestroy(): void {
    console.warn('TotpValidateComponent -> ngOnDestroy')
  }
  
  onOtpChange(otp: any) {
    this.token = otp;
  }

  paste(){
    console.warn('TotpValidateComponent -> paste') 

    navigator.clipboard.readText()
    .then(
      (text: string) => {
        this.ngOtpInput.setValue(text)
        // this.token = text
        this.confirm()
      })
    .catch(error => {
        console.error('Cannot read clipboard text: ', error)
    })
  }

  confirm() {
    console.error('TotpValidateComponent -> confirm');
    
    const secretHex = Array.from(this.email, char => char.charCodeAt(0).toString(16).padStart(2, '0')).join('')
    var ValidateToken = TOTP.validate({
      token: ('000000' + this.token).slice(-6),  
      secret: Secret.fromHex(secretHex),
      algorithm: "SHA1",
      window: 1,
      digits: 6,
      period: 60,
    })

    if (ValidateToken == 0)
      this.dialogRef.close({ data: { data: 'Valida token', token: "OK" } })
    else
      this.errorMessage = "Invalid token!"

  }
}
