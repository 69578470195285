import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Observable, defer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnInit, OnDestroy {
  
  constructor() {

    //Notification
    this._notification = new BehaviorSubject<boolean>(this.currentStatus());
  }

  ngOnInit(): void {
    console.warn('NotificationService -> ngOnInit', this.currentStatus());

  }

  ngOnDestroy(): void {
    console.warn('NotificationService -> ngOnDestroy');
  }

//#region Notification

private currentStatus(): boolean { 
  console.info('NotificationService -> currentStatus', localStorage.getItem('notification'));

  return (localStorage.getItem('notification') == 'on')
}

private _notification!: BehaviorSubject<boolean>;

  notificationModeChange(s: string) {
    localStorage.setItem('notification', s)
    this._notification.next(this.currentStatus())
  }

  notificationToogle(): void {
    console.info('NotificationService -> notificationToogle', this.currentStatus(), !this.currentStatus());

    localStorage.setItem('notification', (!this.currentStatus() ? 'on' : 'off'))
    this._notification.next(this.currentStatus())
  }

  notificationStatus(): Observable<boolean> {
    console.info('NotificationService -> notificationStatus', this.currentStatus());

    return this._notification.asObservable();
  }

//#endregion
  
} 
