<form #createForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <h2 mat-dialog-title>Create Company</h2>
    <mat-dialog-content>
        <div class="inputFile">
            <img *ngIf="company.img" [src]="company.img" alt="Company Logo" class="thumb" (click)="fileInput.click()">
            <img *ngIf="!company.img"
                src="https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoid2VhcmVcL2ZpbGVcL01uWjc4RDF1V2pnbzhBUkxVSGQzLnBuZyJ9:weare:1xUfSJ5wT_EHupK1IW9eirrR739Luv4H3t5tuQr_ZiM?width=1200"
                alt="Company Logo" class="thumb" (click)="fileInput.click()">
            <input hidden (change)="onFileSelected($event)" #fileInput type="file" name="companyLogo" required>
        </div>

        <mat-form-field>
            <mat-label>Company Name</mat-label>
            <input matInput #name [(ngModel)]="company.name" name="company.name" required>
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field>
            <mat-label>Code</mat-label>
            <input matInput #code [(ngModel)]="company.code" name="company.code" required>
        </mat-form-field>
        
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit" [disabled]="!createForm.valid">Save</button>
        <button mat-raised-button color="warm" type="button" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</form>