import { Component } from '@angular/core';

@Component({
  selector: 'app-investments',
  standalone: false,
  templateUrl: './investments.component.html',
  styleUrl: './investments.component.scss'
})
export class InvestmentsComponent {

}
