
<h2 mat-dialog-title>
    <mat-icon>phonelink_lock</mat-icon>
    Sing-In MFA
</h2>
<mat-dialog-content>
    <h3>{{ loginTitle }}</h3>
    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
</mat-dialog-content>
<mat-dialog-actions>

    <div class="action">
        <div class="left">
            <button mat-icon-button (click)="paste()" class="primary">
                <mat-icon>content_paste</mat-icon>
              </button>
        </div>
        <div class="right">
            <button mat-raised-button color="primary" (click)="confirm()">Validate</button>
        </div>
    </div>    
    
</mat-dialog-actions>
<mat-card-footer>
    <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
</mat-card-footer>
