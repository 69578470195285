import { AfterViewInit, Component, Inject, ModelFunction, OnChanges, Optional, SimpleChanges, Type, ViewChild, model } from '@angular/core';
import moment, { Moment } from 'moment';

// import { FixedModel } from '../fixed.model';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FixedModifyComponent } from '../modify/modify.component';


@Component({
  selector: 'fixed-create',
  standalone: false,
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class FixedCreateComponent implements AfterViewInit {
  
  public fixed: any

  @ViewChild('createForm', { static: true }) createForm: NgForm | undefined
  public errorMessage: any
  
  constructor(
    private dialogRef: MatDialogRef<FixedCreateComponent>,
  ){
    this.fixed = {
      id: Math.random().toFixed(5), //crypto.randomUUID(),
      share: '',
      buy: new Date(),
      maturity: new Date(),
      invested: 0.00,
      profitability: 0.00,
      expected: 0.00,
      days: '0'
    }
    console.error('FixedCreateComponent -> constructor', this.fixed)
  }

  ngAfterViewInit(): void {
    console.error('FixedCreateComponent -> ngAfterViewInit')

    //Update total
    this.createForm?.valueChanges?.subscribe((input:any) => {
      // console.error('StockCreateComponent -> createForm.valueChanges', input)

      const date1 = new Date(input['fixed.buy']).getTime()
      const date2 = new Date(input['fixed.maturity']).getTime()
      if(date1 > 0 && date2 > 0)
        this.fixed.days = Math.ceil(Math.abs(date2 - date1) / (1000 * 3600 * 24)).toString() 
    })
  }

  async onSubmit() {
    console.error('FixedCreateComponent -> onSubmit', this.fixed, this.createForm?.valid)

    if (this.createForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Fixed-income created!',
          values: { ...this.fixed }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
