import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Secret, TOTP } from 'otpauth';
import { NgOtpInputComponent } from 'ng-otp-input';

import { TotpValidateComponent } from '../../sign-in/totp-validate/totp-validate.component';

@Component({
  selector: 'app-totp-setup',
  standalone: false,
  templateUrl: './totp-setup.component.html',
  styleUrl: './totp-setup.component.css'
})
export class TotpSetupComponent implements OnInit, OnDestroy {

  qrcodeTitle: string = "Scan QR Code";
  tokenTitle: string = "Enter token";
  email: string = "";
  qrcode: string = "";
  errorMessage: string = "";

  token!: string;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput!: NgOtpInputComponent; //ng-otp-input
  config = {
    allowNumbersOnly: true,
    length: 6,
    inputStyles: {
      width: '40px',
      height: '50px',
    }
  };

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TotpValidateComponent>
  ) {
    this.qrcode = data.qrcode,
    this.email = data.email
  }

  ngOnInit(): void {
    console.error('TotpSetupComponent -> ngOnInit')
  }

  ngOnDestroy(): void {
    console.warn('TotpSetupComponent -> ngOnDestroy');
  }

  onOtpChange(otp: any) {
    this.token = otp;
  }

  paste(){
    console.warn('TotpSetupComponent -> paste') 

    navigator.clipboard.readText()
    .then(
      (text: string) => {
        this.ngOtpInput.setValue(text)
        // this.token = text
        this.confirm()
      })
    .catch(error => {
        console.error('Cannot read clipboard text: ', error)
    })
  }

  confirm() {
    console.error('TotpSetupComponent -> confirm');

    const secretHex = Array.from(this.email, char => char.charCodeAt(0).toString(16).padStart(2, '0')).join('')
    var ValidateToken = TOTP.validate({
      token: ('000000' + this.token).slice(-6),
      secret: Secret.fromHex(secretHex),
      algorithm: "SHA1",
      window: 1,
      digits: 6,
      period: 60,
    })

    if(ValidateToken == 0)
      this.dialogRef.close({ data: { data: 'you confirmed', token: "OK" } })
    else 
      this.errorMessage = "Invalid token!"
  }
}
