import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Observable, defer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnInit, OnDestroy {
  
  constructor() {
    this._theme = new BehaviorSubject<boolean>(this.currentStatus());
  }

  ngOnInit(): void {
    console.warn('ThemeService -> ngOnInit', this.currentStatus());

  }

  ngOnDestroy(): void {
    console.warn('ThemeService -> ngOnDestroy');
  }

//#region Notification

private currentStatus(): boolean { 
  console.info('ThemeService -> currentStatus', localStorage.getItem('theme'));

  return (localStorage.getItem('theme') === 'light')
}

private _theme!: BehaviorSubject<boolean>;

  themeModeChange(s: string) {
    console.info('ThemeService -> themeModeChange >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ', s);

    localStorage.setItem('theme', s)
    this._theme.next(this.currentStatus())
  }

  themeToogle(): void {
    console.info('ThemeService -> themeToogle', this.currentStatus(), !this.currentStatus());

    localStorage.setItem('theme', (!this.currentStatus() ? 'light' : 'dark'))
    this._theme.next(this.currentStatus())
  }

  themeStatus(): Observable<boolean> {
    console.info('ThemeService -> themeStatus', this.currentStatus());

    return this._theme.asObservable();
  }

//#endregion
  
} 
