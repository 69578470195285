<div class="sign-in-container">
  <form #signForm="ngForm" (ngSubmit)="onSubmit()">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>vpn_key</mat-icon>
        </div>
        <mat-card-title>Sign-In</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input matInput type="email" [(ngModel)]="email" name="email" required email>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password" [(ngModel)]="password" name="password" required>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="submit" [disabled]="signForm.invalid">Sign-In</button>
        <button mat-raised-button type="button" routerLink="/account/sign-up">Sign-Up</button>
      </mat-card-actions>
      <mat-card-footer>
        <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
      </mat-card-footer>
    </mat-card>
  </form>
</div>