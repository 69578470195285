<div class="companies-container">
    
            <div class="header">
                <div class="left">
                    <h2 mat-title>Companies</h2>
                </div>
                <div class="right">
                    <button mat-icon-button (click)="create()">
                        <mat-icon class="material-symbols-outlined">{{icons.add}}</mat-icon>
                    </button>
                </div>
            </div>
            <div>
            <table mat-table [dataSource]="list.data">

                <ng-container *ngFor="let col of list.columns; let colIndex = index" matColumnDef="{{col}}">
                    <th mat-header-cell *matHeaderCellDef style="text-transform: capitalize;"> {{col}} </th>
                    
                    <td mat-cell *matCellDef="let element">
                        @if (col != 'active' && col != 'action')
                        {
                            <span>{{element[col]}}</span>
                        } 
                        @if (col == 'active') {
                            <mat-icon class="material-symbols-outlined" *ngIf="element['active']" style="color: rgb(47, 47, 197)">{{icons.active}}</mat-icon>
                            <mat-icon class="material-symbols-outlined" *ngIf="!element['active']" style="color: gray">{{icons.inactive}}</mat-icon>
                        } 
                        @if (col == 'action') {
                            <button mat-icon-button (click)="modify(element)">
                                <mat-icon class="material-symbols-outlined" color="primary">{{icons.edit}}</mat-icon>
                            </button>
                            <button mat-icon-button (click)="delete(element)">
                                <mat-icon class="material-symbols-outlined" color="primary">{{icons.delete}}</mat-icon>
                            </button>
                        }
                    </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="list.columns"></tr>
                <tr mat-row *matRowDef="let row; columns: list.columns;"></tr>

            </table>
            </div>
</div>