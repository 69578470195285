import { AfterViewInit, Component, Inject, Injectable, InjectionToken, ModelFunction, Optional, Type, ViewChild, inject, model } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import moment from 'moment';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AlertService } from '../shared/alert/alert.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvestimentsBase<C, M> {

  icons = environment.icons
  errorMessage: any

  protected service!:any
  private createComponent:ComponentType<C>
  private modifyComponent:ComponentType<M>

  private alert = inject(AlertService);

  private dialog = inject(MatDialog)

  public list: any = {
    columns: [],
    data: []  
  }
  
  constructor(
    create:Type<C>,
    modify:Type<M>
  ) {
    console.log('InvestimentsBase', 'constructor')
    
    this.createComponent = create
    this.modifyComponent = modify
  }

  protected async ngOnInit(): Promise<void> {
    console.error('InvestimentsBase -> ngOnInit')

    this.service.getAll().subscribe( (l:any) => {
      this.list.data = l
    })
  }

  protected ngOnDestroy(): void {
    console.warn('InvestimentsBase -> ngOnDestroy')
  }

  create() {
    console.error('InvestimentsBase -> create', this.createComponent.name)

    const dialogRef = this.dialog.open(this.createComponent as ComponentType<C>, { disableClose: false })
    this.closedDialog(dialogRef)
  }

  modify(e: any) {
    console.error('InvestimentsBase -> modify', this.modifyComponent.name)

    const dialogRef = this.dialog.open(this.modifyComponent, { disableClose: false, data: { ...e } })
    this.closedDialog(dialogRef)
  }

  delete(e: any) {
    console.error('InvestimentsBase -> delete')

    this.service.delete(e)

    this.alert.show('Deleted!', 'delete_forever')
  }

  async attach(e: any, event: Event) {
    console.error('InvestimentsBase -> attach', e, event)

    const file = (event.target as HTMLInputElement).files?.item(0)
    console.error('InvestimentsBase -> attach', 'file', file?.name)
    if(file!=undefined) {
      const storage = getStorage()
      const storageRef = ref(storage, `TradeConfirmations/${e.share}/${file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, await file!.arrayBuffer())
      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'canceled':
              console.log('Upload is canceled')
              break;
            case 'paused':
              console.log('Upload is paused')
              break;
            case 'running':
              console.log('Upload is running')
              break;
              case 'error':
                console.log('Upload error')
                break;
          }
        }, 
        (error) => {
          console.error('Upload error', error)
          this.alert.show('Upload error!', 'fmd_bad')
        }, 
        () => {
          console.log('Ref:', uploadTask.snapshot.metadata, uploadTask.snapshot.ref.toString());
          
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL)

            this.service.updateAttach(e)
            
            const item = this.list.data.filter((i: any) => { return i.id == e.id })[0] 
            item.attach = (!item.attach) ? (1) : (parseInt(item.attach) + 1)

            this.alert.show('File uploaded!', 'attach_file_add')
          })
        }
      )
    } 
    else
    {
      this.alert.show('File not found!', 'fmd_bad')
    }
  }

  closedDialog(dialogRef: MatDialogRef<C | M>) {
    console.error('InvestimentsBase -> closedDialog', dialogRef.componentRef?.componentType.name)

    const type = dialogRef.componentRef?.componentType

    dialogRef.afterClosed().subscribe(async res => {
      console.error('StocksComponent -> res.data.values', res)

      if(res?.data != undefined){

        if(type == this.createComponent){
          console.error('StocksComponent -> closedDialog', type.name)

          if(await this.evaluate(res.data.values)){
            this.service.addItem(res.data.values)

            this.alert.show('Created!', 'save_as')
          }
        }

        if(type == this.modifyComponent){
          console.error('StocksComponent -> closedDialog', type.name)

          if(await this.evaluate(res.data.values)){
            this.service.modify(res.data.values)

            this.alert.show('Modified!', 'save_as')
          } else {
            this.alert.show('Error!', 'error')
          }
        }

      } 
      else 
      {
        if(res != undefined && res?.data != undefined)
          this.alert.show('Data not found!', 'fmd_bad')
      }
    })
  }

  isNumber(o:any){
    // console.error('InvestimentsBase -> isNumber', o, (typeof o === 'number'))

    return typeof o === 'number'
  }

  isTimestamp(o:any){
    // console.error('InvestimentsBase -> isTimestamp', o, 'Type: ', o.seconds !== undefined)

    return o.seconds !== undefined
  }

  isText(o:any){
    // console.log('InvestimentsBase -> isText', o, (!this.isNumber(o)))

    return !this.isNumber(o) && !this.isTimestamp(o)
  }

  //To be overwritten
  protected async evaluate(e:any):Promise<boolean> { return await true }

}
