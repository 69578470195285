import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: false,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent implements OnInit, OnDestroy {

  constructor(){ }

  ngOnInit(): void {
    console.error('FooterComponent -> ngOnInit')
  }

  ngOnDestroy(): void {
    console.warn('FooterComponent -> ngOnDestroy');
  }

}
