import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';

import { NgOtpInputModule } from 'ng-otp-input';

import { AccountRoutingModule } from './account-routes.module';
import { AccountComponent } from './account.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignInService } from './sign-in/sign-in.service';
import { TotpValidateComponent } from './sign-in/totp-validate/totp-validate.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignUpService } from './sign-up/sign-up.service';
import { TotpSetupComponent } from './sign-up/totp-setup/totp-setup.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationService } from './settings/services/notification.service';


@NgModule({
  declarations: [
    AccountComponent,
    SignInComponent,
    SignUpComponent,
    TotpSetupComponent,
    TotpValidateComponent, 
    SettingsComponent
  ],
  imports: [
    CommonModule,

    AccountRoutingModule,

    FormsModule, 
    MatCardModule, 
    MatInputModule, 
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule, 
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSidenavModule,
    
    NgOtpInputModule

  ],
  providers: [
    SignUpService,
    SignInService, 
    NotificationService
  ],
  exports: [
  ]
})
export class AccountModule { }

