import { Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild, input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fixed-modify',
  standalone: false,
  templateUrl: './modify.component.html',
  styleUrl: './modify.component.scss'
})
export class FixedModifyComponent implements OnInit, OnDestroy {

  @ViewChild('modifyForm', { static: true }) modifyForm: NgForm | undefined
  errorMessage!: string

  public fixed: any

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FixedModifyComponent>
  ) {
    console.error('FixedModifyComponent -> constructor', data)
    this.fixed = data
  }

  ngOnInit(): void {
    console.error('FixedModifyComponent -> ngOnInit')
  }

  ngOnDestroy(): void {
    console.warn('FixedModifyComponent -> ngOnDestroy')
  }

  onSubmit(): void {
    console.error('FixedModifyComponent -> onSubmit', this.modifyForm?.value);

    if (this.modifyForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Fixed modified!',
          values: { ...this.fixed }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
