<form #modifyForm="ngForm" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Modify Stock</h2>

      <mat-dialog-content>

        <section>
            <mat-button-toggle-group #side [(ngModel)]="stock.side" [value]="stock.side" name="stock.side" hideSingleSelectionIndicator="true" style="width: 99%;" disabled>        
                <mat-button-toggle value="BUY" style="width: 50%;">Buy</mat-button-toggle>
                <mat-button-toggle value="SELL" style="width: 50%;">Sell</mat-button-toggle>
            </mat-button-toggle-group>
        </section>

        <mat-divider></mat-divider>
        <mat-divider></mat-divider>
        <mat-divider></mat-divider>

        <mat-form-field>
            <mat-label>Share</mat-label>
            <input matInput type="text" [(ngModel)]="stock.share" name="stock.share" disabled>
        </mat-form-field>    
            &nbsp;&nbsp;
        <mat-form-field>
            <mat-label>Operation Date</mat-label>
            <input matInput [matDatepicker]="datepicker" (ngModelChange)="stock.date=$event;" [ngModel]="stock.date.seconds * 1000 | date: 'YYYY-MM-dd'" name="stock.date" disabled/>
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field>
            <mat-label>Amount</mat-label>
            <input matInput type="number" step="1" min="0" (ngModelChange)="stock.amount=$event;" [ngModel]="stock.amount | number:'0.0'" name="stock.amount" placeholder="0" required>
        </mat-form-field>
        &nbsp;&nbsp;
        <mat-form-field>
            <mat-label>Price</mat-label>
            <input matInput type="number" step="0.01" min="0" (ngModelChange)="stock.price=$event;" [ngModel]="stock.price | number:'1.2-2'" name="stock.price" placeholder="0.00" required>
        </mat-form-field>
        
        <mat-divider></mat-divider>

        <mat-form-field>
            <mat-label>Tax</mat-label>
            <input matInput type="number" step="0.01" min="0" (ngModelChange)="stock.tax=$event;" [ngModel]="stock.tax | number:'1.2-2'" name="stock.tax" placeholder="0.00" required>
        </mat-form-field>
        &nbsp;&nbsp;
        <mat-form-field>
            <mat-label>Total</mat-label>
            <input matInput type="number" [ngModel]="stock.total | number:'1.2-2'" name="stock.total" placeholder="0.00" readonly>
        </mat-form-field>
    
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button type="submit" [disabled]="modifyForm.invalid">Save</button>
        <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
      </mat-dialog-actions>

    <!-- <mat-card-footer>
      <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
    </mat-card-footer> -->

</form>
