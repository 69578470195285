import { Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild, input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'share-modify',
  standalone: false,
  templateUrl: './modify.component.html',
  styleUrl: './modify.component.scss'
})
export class ShareModifyComponent implements OnInit, OnDestroy {

  @ViewChild('modifyForm', { static: true }) modifyForm: NgForm | undefined
  errorMessage!: string

  public share: any

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShareModifyComponent>
  ) {
    console.error('ShareModifyComponent -> constructor', data)
    this.share = data
  }

  ngOnInit(): void {
    console.error('ShareModifyComponent -> ngOnInit');
  }

  ngOnDestroy(): void {
    console.warn('ShareModifyComponent -> ngOnDestroy');
  }

  onSubmit(): void {
    console.error('ShareModifyComponent -> onSubmit', this.modifyForm?.value);

    if (this.modifyForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Share modified!',
          values: { ...this.share }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
