export const environment = {

  ENVIRONMENT: 'DEV', 

  firebaseConfig: {
    apiKey: "AIzaSyDgeZJSadZFUSpbGyXl_RiZdcnERv3zz6k",
    authDomain: "retirolez.firebaseapp.com",
    projectId: "retirolez",
    storageBucket: "retirolez.appspot.com",
    messagingSenderId: "10584678581",
    appId: "1:10584678581:web:29f11ac51ae433b9769efc"
  },

  jwtSecret: "MySecretDev",

  token: {
    label: 'Retirolêz',
    algorithm: 'SHA1',
    digits: 6,
    period: 60
  },

  icons: {
    active: 'check_circle',
    inactive: 'do_not_disturb_on',

    buy: 'download_for_offline',
    sell: 'publish',

    add: 'add_circle', 
    edit: 'ink_pen',
    delete: 'delete_forever',
    attach: 'attach_file_add'
  }

}