import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { SignInService } from './sign-in.service';
import { TotpValidateComponent } from './totp-validate/totp-validate.component';
import { SidenavService } from '../../shared/sidenav/sidenav.service';
import { NotificationService } from '../settings/services/notification.service';
import { ThemeService } from '../settings/services/theme.service';

@Component({
  selector: 'app-sign-in',
  standalone: false,
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css'
})
export class SignInComponent implements OnInit, OnDestroy {

  email: string = 'icaro@interligados.net';
  password: string = '123456';
  errorMessage: string = '';

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private signInService: SignInService,
    private sidenavService: SidenavService,
    private themeService: ThemeService,
    private notificationService: NotificationService
  ) { 
    localStorage.removeItem('token')
  }

  ngOnInit(): void {
    console.error('SignInComponent -> ngOnInit')
  }

  ngOnDestroy(): void {
    console.warn('SignInComponent -> ngOnDestroy')
  }

  async onSubmit() {
    console.error('SignInComponent -> onSubmit', this.email, this.password)

    await this.signInService.signIn(this.email, this.password)
      .then(async (data: any) => {

        if (data == undefined)
          throw new Error("Data is empty!")
        if (data?.errorCode != undefined)
          throw new Error(data.errorMessage)

        console.log('data: ', data);
        localStorage.setItem('user', data)
        
        this.openDialog()
      })
      .catch((error) => {
        this.errorMessage = error.code + ": " + error.message
      })
  }

  openDialog() {
    console.error('SignInComponent -> openDialog', 'TotpValidateComponent')

    let dialogRef = this.dialog.open(TotpValidateComponent, {
      disableClose: true,
      data: {
        email: this.email
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      console.error('SignUpComponent -> dialogRef.afterClosed()', 'TotpValidateComponent')

      console.log("res.data", res.data)
      //TODO: Validar se o token é "OK"
      this.errorMessage = res.data.token
      
      localStorage.setItem('token', "OK")

      let user:any = localStorage.getItem('user') 
      user = JSON.parse(user)
      console.log('UserMode: ', user);
      
      this.themeService.themeModeChange(user.settings.theme)

      this.sidenavService.sidenavStatusChange(user.settings.sidenav.status)
      this.sidenavService.sidenavModeChange(user.settings.sidenav.mode)
      this.sidenavService.sidenavSizeChange(user.settings.sidenav.size)

      this.notificationService.notificationModeChange(user.settings.notification)

      this.router.navigateByUrl('/home')
    })
  }

}
