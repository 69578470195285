<app-header [inputSideNav]="drawer"></app-header>  

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer [class]="sidenavClass" fixedInViewport
    [mode]="sidenavMode"  
    [opened]="sidenavShow">
    <div class="menu-buttons">
      <button mat-raised-button routerLink="/home">
        <mat-icon>home</mat-icon>
        <span *ngIf="!collapsed">Home</span>
      </button>
      <button mat-raised-button routerLink="/investments">
        <mat-icon>show_chart</mat-icon>
        <span *ngIf="!collapsed">Investments</span>
      </button>
      <!-- <mat-expansion-panel [class.mat-elevation-z0]="true" dense>
        <mat-expansion-panel-header>
        <mat-icon>show_chart</mat-icon>
            Preference
        </mat-expansion-panel-header>
        <mat-nav-list dense>
            <a mat-list-item routerLink="#"><mat-icon>attach_money</mat-icon>Billing</a>
            <a mat-list-item routerLink="#"><mat-icon>notification_important</mat-icon>Notification</a>
        </mat-nav-list>
    </mat-expansion-panel> -->
      <button mat-raised-button routerLink="/account">
        <mat-icon>account_circle</mat-icon>
        <span *ngIf="!collapsed">Account</span>        
      </button>
      <button mat-raised-button routerLink="/account/sign-in">
        <mat-icon>vpn_key</mat-icon>
        <span *ngIf="!collapsed">Sign-In</span>        
      </button>
      <button mat-raised-button routerLink="/account/sign-up">
        <mat-icon>assignment</mat-icon>
        <span *ngIf="!collapsed">Sign-Up</span>        
      </button>
      <button mat-raised-button routerLink="/account/settings">
        <mat-icon>tune</mat-icon>
        <span *ngIf="!collapsed">Settings</span>        
      </button>
    </div>
  
    <div class="spacer"></div>
    <div class="bottom-button">
      <button mat-icon-button (click)="collapseToggle()">
        <mat-icon *ngIf="!collapsed" class="material-symbols-outlined">keyboard_double_arrow_left</mat-icon>
        <mat-icon *ngIf="collapsed" class="material-symbols-outlined">keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  </mat-sidenav>

  <mat-sidenav-content #sidenavContent [ngStyle]="{ 'margin-left.px': (contentMargin && drawer.opened) ? contentMargin : 0 }">
  
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>


