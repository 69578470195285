import { Injectable } from '@angular/core';
import * as Jwt from "jose"

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentData, DocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, UserCredential, sendPasswordResetEmail, signOut, updateCurrentUser, signInWithCustomToken } from "firebase/auth";
import { collection, doc, setDoc, getFirestore, getDocs, where, query, documentId, getDoc, getDocFromCache } from "firebase/firestore";

import { environment } from '../../../../environments/environment';
import { UserModel } from '../user.model';


@Injectable({
  providedIn: 'root'
})
export class SignInService {

  private secret: string = (environment.jwtSecret != undefined) ? environment.jwtSecret : "MySecret";

  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth
  ) { }

  async signIn(email: string, password: string): Promise<any> {
    console.info('SignInService.signIn', email, password);

    let user: UserModel
    let message: any = ""
    let isVerified = false

    await this.auth.signInWithEmailAndPassword(email, password)
      .then(async () => {

        await this.auth.onAuthStateChanged(async (user: any) => {
          console.info("user: ", user.uid);

          if (user) {
            var email = user.email;
            var emailVerified = user.emailVerified;

            const getDocUser = await getDoc(doc(getFirestore(), 'users', user.uid))
            const getDocDataUser = getDocUser.data()

            if (getDocDataUser === undefined || user.email == undefined)
              throw new Error("User not found!")

            if (emailVerified == true) {
              isVerified = true
            } else {
              console.debug(`Email: ${email} - Verified: ${emailVerified}`)

              this.auth.languageCode = (getDocDataUser != undefined) ? getDocDataUser["languageCode"] : undefined;
              await sendEmailVerification(user)
              .then(()=> {
                console.debug("Resent email verification");
              })
            }

            console.warn('JSON: getDocDataUser', getDocDataUser)
            const ret: any = JSON.parse(JSON.stringify(getDocDataUser))
            delete ret.password
            ret.uid = user.uid
            ret.message = "OK"

            // console.log(secret)
            // ret.token = await new Jwt.EncryptJWT()
            //                       .setProtectedHeader({ alg: 'dir', enc: 'A128GCM' })
            //                       .encrypt(new Uint8Array(16))
            

            user = {
              name: ret.name,
              email: ret.email,
              token: ret.token,
              settings: ret.settings
            };
            console.log('UserModel: ', user);
            
            localStorage.setItem('user', JSON.stringify(user));

            message = JSON.stringify(user)
          }
        })
      })
      .catch((error) => {

        console.warn("signInWithEmailAndPassword.catch", "Error code: " + error.code, "Error Message: " + error.message);

        message = { errorCode: error.code, errorMessage: error.message };
      });

    return message
  }

  async signOut() {
    console.info('SignInService.signOut');

    localStorage.removeItem('token');

    return await this.auth.signOut();
  }
}
