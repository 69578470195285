import { AfterViewInit, Component, Inject, ModelFunction, Optional, ViewChild, model } from '@angular/core';
import moment from 'moment';

import { DividendModel } from '../dividend.model';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DividendModifyComponent } from '../modify/modify.component';


@Component({
  selector: 'dividend-create',
  standalone: false,
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class DividendCreateComponent implements AfterViewInit {
  
  public dividend: any

  @ViewChild('createForm', { static: true }) createForm: NgForm | undefined
  public errorMessage: any
  
  constructor(
    private dialogRef: MatDialogRef<DividendModifyComponent>,
  ){
    this.dividend = {
      id: Math.random().toFixed(3),
      share: '',
      date: new Date(),
      amount: 0,
      value: 0.00,
      total: 0.00
    }
  }

  ngAfterViewInit(): void {
    console.error('DividendCreateComponent -> ngAfterViewInit')

    //Update total
    this.createForm?.valueChanges?.subscribe((input:any) => {
      // console.error('DividendCreateComponent -> createForm.valueChanges', input)
      
      if(input['dividend.amount']  != undefined && input['dividend.value'] != undefined)
        this.dividend.total = (parseInt(input['dividend.amount']) * parseFloat(input['dividend.value']))
    })
  }

  async onSubmit() {
    console.error('DividendCreateComponent -> onSubmit', this.dividend, this.createForm?.valid)

    if (this.createForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Dividend created!',
          values: { ...this.dividend }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
