  <form #createForm="ngForm" (ngSubmit)="onSubmit()">
      <h2 mat-dialog-title>Create Fixed-income</h2>

      <mat-dialog-content>

          <mat-form-field style="width: 100%;">
              <mat-label>Share</mat-label>
              <mat-select #share [(ngModel)]="fixed.share" name="fixed.share" required>
                <mat-option value="LTF-2025">Tesouro Selic 2025</mat-option>
                <mat-option value="LTN-2026">Tesouro Prefixado 2026</mat-option>
                <mat-option value="LTF-2029">Tesouro Selic 2029</mat-option>
                <mat-option value="LTN-2029">Tesouro Prefixado 2029</mat-option>
              </mat-select>
          </mat-form-field>

          <mat-divider></mat-divider>

          <mat-form-field>
            <mat-label>Buy date</mat-label>
            <input matInput [matDatepicker]="dateBuy" (ngModelChange)="fixed.buy=$event._d;" [ngModel]="fixed.buy.seconds * 1000 | date: 'YYYY-MM-dd'" name="fixed.buy" />
            <mat-datepicker-toggle matIconSuffix [for]="dateBuy"></mat-datepicker-toggle>
            <mat-datepicker touchUi #dateBuy></mat-datepicker>
          </mat-form-field>
          &nbsp;&nbsp;
          <mat-form-field>
            <mat-label>Maturity date</mat-label>
            <input matInput [matDatepicker]="dateMaturity" (ngModelChange)="fixed.maturity=$event._d;" [ngModel]="fixed.maturity.seconds * 1000 | date: 'YYYY-MM-dd'" name="fixed.maturity" />
            <mat-datepicker-toggle matIconSuffix [for]="dateMaturity"></mat-datepicker-toggle>
            <mat-datepicker touchUi #dateMaturity></mat-datepicker>
          </mat-form-field>

          <mat-divider></mat-divider>

          <mat-form-field>
              <mat-label>Invested Amount</mat-label>
              <input matInput type="number" (ngModelChange)="fixed.invested=$event;" [ngModel]="fixed.invested | number:'1.2-2'" name="fixed.invested" placeholder="0.00" required>
          </mat-form-field>
          &nbsp;&nbsp;
          <mat-form-field>
              <mat-label>Profitability</mat-label>
              <input matInput type="number" step="0.01" (ngModelChange)="fixed.profitability=$event;" [ngModel]="fixed.profitability | number:'1.2-2'" name="fixed.profitability" placeholder="0.00%" required>
          </mat-form-field>

          <mat-divider></mat-divider>

          <mat-form-field>
              <mat-label>Expected Return</mat-label>
              <input matInput type="number" step="0.01" (ngModelChange)="fixed.expected=$event;" [ngModel]="fixed.expected | number:'1.2-2'" name="fixed.expected" placeholder="0.00" required>
          </mat-form-field>
          &nbsp;&nbsp;
          <mat-form-field>
              <mat-label>Total days</mat-label>
              <input matInput type="number" [ngModel]="fixed.days" name="fixed.days" disabled readonly>
          </mat-form-field>
          
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-raised-button type="submit" [disabled]="createForm.invalid">Add</button>
          <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
        </mat-dialog-actions>

      <!-- <mat-card-footer>
        <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
      </mat-card-footer> -->
  </form>