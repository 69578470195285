import { Injectable, OnDestroy, OnInit, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertComponent } from './alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  
  private snackBar = inject(MatSnackBar)

  constructor() { }

  public show(m:string, i:string): void { 
    console.info('AlertService -> show', m , i)

    this.snackBar.openFromComponent(AlertComponent, { data: {
      message:  m, 
      icon: i
    }})
  }

}
