import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routes.module';

import { AccountModule } from './components/account/account.module';
import { HomeModule } from './components/home/home.module';
import { InvestmentsModule } from './components/investments/investments.module';
import { SharedModule } from './components/shared/shared.module';

import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

//firebase.initializeApp(environment.firebaseConfig);


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule, 
    
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    
    AppRoutingModule,
    HomeModule,
    AccountModule,
    InvestmentsModule,
    SharedModule

  ],
  providers: [
    { provide:  DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { 
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
      useValue: {
        duration: 2000,
        verticalPosition: 'top' as MatSnackBarVerticalPosition,
        horizontalPosition: 'center' as MatSnackBarHorizontalPosition
      }
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'DD, MMM YY',
          monthLabel: 'MMMM',
          monthYearLabel: 'YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        },
      }
    },
  ],
  exports: [
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }