import { Component, OnDestroy, OnInit } from '@angular/core';
// import {NgFor} from '@angular/common';
// import { MatGridListModule, MatGridTile } from '@angular/material/grid-list';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-home',
  standalone: false,
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit, OnDestroy {

  tiles: Tile[] = [
    {text: 'One', cols: 3, rows: 1, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    {text: 'Three', cols: 1, rows: 1, color: 'lightpink'},
    {text: 'Four', cols: 2, rows: 1, color: '#DDBDF1'},
  ]

  ngOnInit(): void {
    console.error('HomeComponent -> ngOnInit');
  }

  ngOnDestroy(): void {
    console.warn('HomeComponent -> ngOnDestroy');
  }
}
