import { Component, OnDestroy, OnInit } from '@angular/core';

import { Secret, TOTP } from 'otpauth';
import { toDataURL } from 'qrcode';

import { AccountService } from './account.service';

@Component({
  selector: 'app-account',
  standalone: false,
  templateUrl: './account.component.html',
  styleUrl: './account.component.css'
})
export class AccountComponent implements OnInit, OnDestroy {

  qrcode: string = ''
  
  user: any = {
    name: "",
    email: ""
  }

  constructor(
    private accountService: AccountService
  ){ }

  async ngOnInit(): Promise<void> {
    console.error('AccountComponent -> ngOnInit')

    this.user = await this.accountService.getUserData()
    console.log("ngOnInit", this.user);

    const secretHex = Array.from(this.user.email, (char: any) => char.charCodeAt(0).toString(16).padStart(2, '0')).join('');
    
    this.user.token = new TOTP({
      issuer: this.user.name,
      label: "Retirolêz",
      algorithm: "SHA1",
      digits: 6,
      period: 60,
      secret: Secret.fromHex(secretHex),
    }).toString();

    toDataURL(this.user.token)
    .then((s: string) => {
      this.qrcode = s;
    })
  }

  ngOnDestroy(): void {
    console.warn('AccountComponent -> ngOnDestroy')
  }

}
