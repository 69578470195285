import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService implements OnInit, OnDestroy {
  
  constructor() {
    //Sidenav status
    this._sidenavStatus = new BehaviorSubject<boolean>(this.currentStatus())
    this._sidenavMode = new BehaviorSubject<string>(this.currentMode())
    this._sidenavSize = new BehaviorSubject<string>(this.currentSize())
  }

  ngOnInit(): void {
    console.warn('SidenavService -> ngOnInit', this.currentStatus())
  }

  ngOnDestroy(): void {
    console.warn('SidenavService -> ngOnDestroy');
  }

//#region Sidenav status

  private currentStatus(): boolean { 
    console.info('SidenavService -> currentStatus', localStorage.getItem('sidenav'))

    return (localStorage.getItem('sidenav') == 'opened') 
  }

  private _sidenavStatus!: BehaviorSubject<boolean>;

  sidenavStatusChange(b: boolean): void {
    console.info('SidenavService -> sidenavToogle', b)

    localStorage.setItem('sidenav', (b ? 'opened' : 'closed'))
    this._sidenavStatus.next(this.currentStatus())
  }

  sidenavToggle(): void {
    console.info('SidenavService -> sidenavToogle', this.currentStatus(), !this.currentStatus())

    localStorage.setItem('sidenav', (!this.currentStatus() ? 'opened' : 'closed'))
    this._sidenavStatus.next(this.currentStatus())
  }

  sidenavStatus(): Observable<boolean> {
    console.info('SidenavService -> sidenavStatus', this.currentStatus())

    return this._sidenavStatus.asObservable()
  }

//#endregion

//#region Sidenav mode

private _sidenavMode!: BehaviorSubject<string>;

private currentMode(): string { 
  console.info('SidenavService -> currentMode', localStorage.getItem('sidenav.mode'))

  var reply: any = localStorage.getItem('sidenav.mode');
  if (reply == null) {
    console.info('SidenavService -> currentMode -> reply', reply)
    reply = 'side'
  }
  return reply;
}

sidenavModeChange(s: string): void {
  console.info('SidenavService -> sidenavModeChange', s);

  localStorage.setItem('sidenav.mode', s)
  this._sidenavMode.next(s)
}

sidenavMode(): Observable<string> {
  console.info('SidenavService -> sidenavMode', this.currentMode())

  return this._sidenavMode.asObservable()
}

//#endregion

//#region Sidenav size

private _sidenavSize!: BehaviorSubject<string>;

private currentSize(): string { 
  console.info('SidenavService -> currentSize', localStorage.getItem('sidenav.size'))

  var reply: any = localStorage.getItem('sidenav.size');
  if (reply == null) {
    console.info('SidenavService -> currentSize -> reply', reply)
    reply = 'sidenav'
  }
  return reply;
}

sidenavSizeChange(s: string): void {
  console.info('SidenavService -> sidenavSizeChange', s)

  localStorage.setItem('sidenav.size', s)
  this._sidenavSize.next(s)
}

sidenavSizeToggle(): void {
  console.info('SidenavService -> sidenavSizeToggle', this.currentSize());

  localStorage.setItem('sidenav.size', (this.currentSize() == 'sidenav') ? 'collaped' : 'sidenav'  )
  this._sidenavSize.next(this.currentSize())
}

sidenavSize(): Observable<string> {
  console.info('SidenavService -> sidenavSize', this.currentSize());

  return this._sidenavSize.asObservable();
}

//#endregion

}
