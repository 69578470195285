import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Error404Component } from '../shared/errors/errors.module';

import { AppGuard } from '../../app.guard';
import { InvestmentsComponent } from './investments.component';
import { CompaniesComponent } from './companies/companies.component';
import { SharesComponent } from './shares/shares.component';
import { StocksComponent } from './stocks/stocks.component';
import { ReitsComponent } from './reits/reits.component';
import { DividendsComponent } from './dividends/dividends.component';
import { FixedComponent } from './fixed/fixed.component';


export const routes: Routes = [
  {
    path: 'investments',
    data: { title: 'Investments' },
    children: [
      // { path: '', redirectTo: 'companies', pathMatch: 'full' }, 
      { path: '', component: InvestmentsComponent, canActivate: [AppGuard] }, // Default route
      { path: 'companies', component: CompaniesComponent, canActivate: [AppGuard] },
      { path: 'shares', component: SharesComponent, canActivate: [AppGuard] },
      { path: 'stocks', component: StocksComponent, canActivate: [AppGuard] },
      { path: 'reits', component: ReitsComponent, canActivate: [AppGuard] },
      { path: 'dividends', component: DividendsComponent, canActivate: [AppGuard] },
      { path: 'fixed-incomes', component: FixedComponent, canActivate: [AppGuard] },

      { path: '**', component: Error404Component }, // Error 404
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvestmentsRoutingModule { }
