import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-error404',
  standalone: false,
  templateUrl: './error404.component.html',
  styleUrl: './error404.component.css'
})
export class Error404Component implements OnInit, OnDestroy {

  constructor(){ }

  ngOnInit(): void {
    console.error('Error404Component -> ngOnInit')
  }

  ngOnDestroy(): void {
    console.warn('Error404Component -> ngOnDestroy');
  }

}
