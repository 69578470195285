import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'company-create',
  standalone: false,
  templateUrl: './create.component.html',
  styleUrl: './create.component.css'
})
export class CompanyCreateComponent implements OnInit, OnDestroy {

  @ViewChild('createForm', { static: true }) createForm!: NgForm
  imageSrc: string | ArrayBuffer | null = null
  errorMessage!: string

  company:any = {
    id: Math.random().toFixed(2),
    name: '', 
    code: '', 
    active: true, 
    img: ''
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CompanyCreateComponent>) { }

  ngOnInit(): void {
    console.error('CreateComponent -> ngOnInit');
  }

  ngOnDestroy(): void {
    console.warn('CreateComponent -> ngOnDestroy');
  }

  onFileSelected(event: Event): void {
    console.error('CreateComponent -> onFileSelected');

    const input = event.target as HTMLInputElement
    if (input && input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader()
      reader.onload = () => {
        this.company.img = reader.result
      };
      reader.readAsDataURL(file)
      // reader.readAsArrayBuffer(file);
    }
  }

  onSubmit(): void {
    console.error('CreateComponent -> onSubmit', this.company);

    if (this.createForm.valid)
      this.dialogRef.close({
        data: {
          response: 'Submitted create form!',
          values: { ...this.company }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
