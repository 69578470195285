import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentData, DocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, UserCredential, sendPasswordResetEmail, signOut, updateCurrentUser, signInWithCustomToken } from "firebase/auth";
import { collection, doc, setDoc, getFirestore, getDocs, where, query, documentId, getDoc, getDocFromCache } from "firebase/firestore";

import { UserModel } from '../user.model';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth
  ) { }

  async signUp(name: string, email: string, password: string, token: string): Promise<any> {
    console.log('SignUpService.signUp')

    var userEmail = email
    var userPass = password
    let message: any

    await this.auth.createUserWithEmailAndPassword(userEmail, userPass)
      .then(async (userCredential: any): Promise<any> => {
        
        console.log("userCredential", userCredential)

        await this.auth.updateCurrentUser(userCredential.user)

        // auth.languageCode = data.language;
        await sendEmailVerification(userCredential.user)

        return await this.auth.signInWithEmailAndPassword(userEmail, password)
      })
      .then(async (userCredential: UserCredential) => {
        console.log("SignUpService.signUp: userCredential", userCredential.user)

        const user: UserModel = {
          uid: userCredential.user.uid, 
          name: name, 
          email: email, 
          // password: password, 
          token: token, 
          settings: {
            theme: 'light', 
            sidenav: {
                type: 'side',
                size: 'collapsed',
                status: true
            },
            notification: 'on',
          }
        }

        var usersCollection = this.db.collection("users")
        //await usersCollection.doc(userCredential?.user?.uid).set({ 
        await usersCollection.doc(email).set(user)

        message = user
      })
      .catch((error): any => {
        
        console.error("Error code: " + error.code, "Error Message: " + error.message);

        message = { errorCode: error.code, errorMessage: error.message };
      })

    return message;
  }

  resetPassword(email: string): void {    
    console.log('SignUpService.resetPassword', this.auth.currentUser)

    this.auth.useDeviceLanguage();

    this.auth.sendPasswordResetEmail(email)
      .then(() => {
        return "OK"
      })
      .catch((error) => {
        //error code
        var errorCode = error.code as string
        //errod message
        var errorMessage = error.message as string
        //show error message
        console.error("Error code: " + errorCode, "Error Message: " + errorMessage);

        return error;
      })
  }
}
