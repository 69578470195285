<mat-card appearance="outlined">
    <mat-card-header>
        <h2>Theme</h2>
    </mat-card-header>
    <mat-card-content>
        <section>
            <mat-button-toggle-group #themeApp="matButtonToggleGroup" [value]="theme" (change)="themeToggle()" hideSingleSelectionIndicator="true">        
                <mat-button-toggle value="light"><mat-icon>sunny</mat-icon></mat-button-toggle>
                <mat-button-toggle value="dark"><mat-icon>nightlight</mat-icon></mat-button-toggle>
            </mat-button-toggle-group>
        </section>
    </mat-card-content>
</mat-card>

<mat-card appearance="outlined">
    <mat-card-header>
        <h2>Sidenav</h2>
    </mat-card-header>
    <mat-card-content>
        <section>
            <mat-button-toggle-group #mode="matButtonToggleGroup" [value]="modeNav" (change)="onModeChange(mode.value)" hideSingleSelectionIndicator="true" >        
                <mat-button-toggle value="push">Push</mat-button-toggle>
                <mat-button-toggle value="over">Over</mat-button-toggle>
                <mat-button-toggle value="side">Side</mat-button-toggle>
            </mat-button-toggle-group>
            &nbsp;&nbsp;
            <mat-button-toggle-group #size="matButtonToggleGroup" [value]="sizeNav" (change)="onSizeChange(size.value)" hideSingleSelectionIndicator="true" >        
                <mat-button-toggle value="sidenav">Normal</mat-button-toggle>
                <mat-button-toggle value="collaped">Mini</mat-button-toggle>
            </mat-button-toggle-group>
        </section>
    </mat-card-content>
</mat-card>

<mat-card appearance="outlined">
    <mat-card-header>
        <h2>Notification</h2>
    </mat-card-header>
    <mat-card-content>
        <section>
            <mat-button-toggle-group #notification="matButtonToggleGroup" [value]="notificationStatus" (change)="notificationToggle()" hideSingleSelectionIndicator="true">        
                <mat-button-toggle value="on"><mat-icon>notifications_active</mat-icon></mat-button-toggle>
                <mat-button-toggle value="off"><mat-icon>notifications_off</mat-icon></mat-button-toggle>
            </mat-button-toggle-group>
        </section>
    </mat-card-content>
</mat-card>