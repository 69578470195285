import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collection, doc, setDoc, count, sum, average, getAggregateFromServer, getFirestore, deleteDoc, getDocs, where, query, documentId, getDoc, updateDoc, getDocFromCache, addDoc, orderBy, increment } from "firebase/firestore";
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FixedService {

  private incomes!: BehaviorSubject<any[]>;

  constructor(
    private db: AngularFirestore,
  ) {
    this.incomes = new BehaviorSubject<any[]>([])
    this.selectAll()
  }

  public async selectAll(): Promise<void> {
    // console.log('FixedService', 'selectAll')

    const incomes:any[] = []

    // const q = query(collection(getFirestore(), "Stocks"), where("side", "==", 'BUY'), orderBy('side', 'asc'))
    const q = query(collection(getFirestore(), "FixedIncomes"))
    const allDocs = await getDocs(q)
    allDocs.docs.forEach((d:any, j:any, h:any) => {
      // console.log('Doc:', d.id, j, h)
      const doc = { ...d.data(), id: d.id }
      incomes.push(doc)
    })

    this.incomes.next(incomes)
  }

  getAll(): Observable<any[]> {
    console.log('FixedService', 'getAll')

    return this.incomes.asObservable();
  }
  
  addItem(e:any):void {
    console.log('FixedService', 'addItem', e)

    addDoc(collection(getFirestore(), "FixedIncomes"), { ...e, days: e.days.toString(), created_on: moment().unix(), modified_on: moment().unix() })
    // this.incomes.next([e, ...this.incomes.value])
    this.selectAll()
  }

  delete(e:any): void{
    console.log('FixedService', 'delete', e.id)

    deleteDoc(doc(getFirestore(), "FixedIncomes", e.id))
    this.selectAll()
  }

  modify(e:any): void{
    console.log('FixedService', 'modify', e.id)

    updateDoc(doc(getFirestore(), "FixedIncomes", e.id), { ...e, days: e.days.toString(), modified_on: moment().unix() })
    this.selectAll()
  }

  async updateAttach(e: any) {
    console.log('FixedService', 'updateAttach', e.id)
    
    await updateDoc(doc(getFirestore(), "FixedIncomes", e.id), {
      attach: increment(1)
    })
    .then(() => {
      console.log('FixedService', 'updateAttach', e.id, 'UPDATED')
    })
    
  }

}
