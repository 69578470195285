<div class="reits-container">
    
    <div class="header">
        <div class="left">
            <h2 mat-title>Reits</h2>
        </div>
        <div class="right">
            <button mat-icon-button (click)="create()">
                <mat-icon class="material-symbols-outlined">{{icons.add}}</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <table mat-table [dataSource]="list.data">
            
            <ng-container *ngFor="let col of list.columns; let colIndex = index" matColumnDef="{{col}}">
                <th mat-header-cell *matHeaderCellDef style="text-transform: capitalize;"> {{col}} </th>
                
                <td mat-cell *matCellDef="let element">
                    @if (col != 'side' && col != 'action')
                    {
                        <span *ngIf="isTimestamp(element[col])">{{element[col].seconds * 1000 | date}}</span>
                        <span *ngIf="isNumber(element[col])">{{element[col] | currency }}</span>
                        <span *ngIf="isText(element[col])">{{element[col]}}</span>
                    } 
                    @if (col == 'side') {
                        <mat-icon class="material-symbols-outlined" *ngIf="element['side']=='BUY'" style="color: green">{{icons.buy}}</mat-icon>
                        <mat-icon class="material-symbols-outlined" *ngIf="element['side']=='SELL'" style="color: red">{{icons.sell}}</mat-icon>
                    } 
                    @if (col == 'action') {
                        <input hidden (change)="attach(element, $event)" #fileInput type="file" name="attach">
                        <button mat-icon-button (click)="fileInput.click()">
                            <mat-icon aria-hidden="false" matBadge="{{element.attach}}" matBadgePosition="before" color="primary" class="material-symbols-outlined">{{icons.attach}}</mat-icon>
                        </button>
                        <button mat-icon-button (click)="modify(element)">
                            <mat-icon color="primary" class="material-symbols-outlined">{{icons.edit}}</mat-icon>
                        </button>
                        <button mat-icon-button (click)="delete(element)">
                            <mat-icon color="primary" class="material-symbols-outlined">{{icons.delete}}</mat-icon>
                        </button>
                    }
                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="list.columns"></tr>
            <tr mat-row *matRowDef="let row; columns: list.columns;"></tr>

        </table>
    </div>
</div>