import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Retirolêz';

  constructor(){ }

  ngOnInit(): void {
    console.error('AppComponent -> ngOnInit')
  }

  ngOnDestroy(): void {
    console.warn('AppComponent -> ngOnDestroy');
  }
}
