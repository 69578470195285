import { AfterViewInit, Component, Inject, ModelFunction, Optional, ViewChild, model } from '@angular/core';
import moment from 'moment';

import { StockModel } from '../stock.model';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StockModifyComponent } from '../modify/modify.component';
import { randomBytes } from 'crypto';


@Component({
  selector: 'stock-create',
  standalone: false,
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class StockCreateComponent implements AfterViewInit {
  
  public stock: any

  @ViewChild('createForm', { static: true }) createForm: NgForm | undefined
  public errorMessage: any
  
  constructor(
    private dialogRef: MatDialogRef<StockModifyComponent>,
  ){
    this.stock = {
      id: Math.random().toFixed(3),
      share: '',
      date: new Date(),
      side: '',
      amount: 0,
      price: 0.00,
      tax: 0.00,
      total: 0.00
    }
  }

  ngAfterViewInit(): void {
    console.error('StockCreateComponent -> ngAfterViewInit')

    //Update total
    this.createForm?.valueChanges?.subscribe((input:any) => {
      console.error('StockCreateComponent -> createForm.valueChanges', input)

      if(input['stock.amount'] != undefined && input['stock.price'] != undefined && input['stock.tax'] != undefined )
        this.stock.total = (parseInt(input['stock.amount']) * parseFloat(input['stock.price'])) + parseFloat(input['stock.tax'])
    })
  }

  async onSubmit() {
    console.error('StockCreateComponent -> onSubmit', this.stock, this.createForm?.valid)

    if (this.createForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Stock created!',
          values: this.stock
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
  
}
