import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const AccountGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  
  if (localStorage.getItem('token') == "OK") {
    router.navigate(['/home']);
    return false;
  }
  
  return true;
};
