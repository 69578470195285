import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, WritableSignal, signal } from '@angular/core';

import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SidenavService } from '../../shared/sidenav/sidenav.service';
import { ThemeService } from './services/theme.service';
import { NotificationService } from './services/notification.service';


@Component({
  selector: 'app-settings',
  standalone: false,
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnInit, OnDestroy {

  hideSingle = signal<boolean>(false);
  modeNav!: string // = 'side'
  sizeNav!: string //= 'sidenav'
  theme!: string
  notificationStatus!: string
  
  constructor(
    private sidenavService: SidenavService,
    private themeService: ThemeService,
    private notificationService: NotificationService
  ){ }

  ngOnInit(): void {
    console.warn('SettingsComponent -> ngOnInit')

    this.themeService.themeStatus().subscribe( (b) => {
      console.log('SettingsComponent -> themeStatus:subscribe', b)
      this.theme = (b) ? 'light' : 'dark' 
    })

    this.notificationService.notificationStatus().subscribe( (b) => {
      console.log('SettingsComponent -> notificationStatus:subscribe', b)
      this.notificationStatus = (b) ? 'on' : 'off'
    })

    //Sidenavc - Mode
    this.sidenavService.sidenavMode().subscribe( (s) => { 
      console.log('SettingsComponent -> sidenavMode:subscribe', s)
      this.modeNav = s
     })
     //Sidenavc - Size
     this.sidenavService.sidenavSize().subscribe( (s) => { 
       console.log('SettingsComponent -> sidenavSize:subscribe', s)
       this.sizeNav = s
      })
  }

  ngOnDestroy(): void {
    console.warn('SettingsComponent -> ngOnDestroy')
  }
  
  onModeChange(s:string){
    console.log('SettingsComponent -> onModeChange', s)

    this.sidenavService.sidenavModeChange(s);    
  }
  
  onSizeChange(s:string){
    console.log('SettingsComponent -> onSizeChange', s)

    this.sidenavService.sidenavSizeChange(s);    
  }

  themeToggle(): void {
    this.themeService.themeToogle();
  }

  notificationToggle(): void {
    this.notificationService.notificationToogle();
  }

}
