import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Type, inject } from '@angular/core';
import moment from 'moment';

import { InvestimentsBase } from '../investiments.base';
import { DividendsService } from './dividends.service';
import { DividendCreateComponent } from './create/create.component';
import { DividendModifyComponent } from './modify/modify.component';


@Component({
  selector: 'app-dividends',
  standalone: false,
  templateUrl: './dividends.component.html',
  styleUrl: './dividends.component.scss'
})
export class DividendsComponent extends InvestimentsBase<DividendCreateComponent, DividendModifyComponent> implements AfterViewInit {

  override service = inject(DividendsService)

  override list : any = {
    columns: [
      'date', 'share', 'amount', 'value', 'total', 'action'
    ],
    data: [
      // { id: 1, share: 'BCFF11', date: moment('2024-07-01').format('YYYY-MM-DD'), amount: '100', value:   0.15, total:  60.10 },
      // { id: 2, share: 'HCTR11', date: moment('2024-07-01').format('YYYY-MM-DD'), amount:  '25', value:   0.05, total: 760.20 },
      // { id: 3, share: 'MXRF11', date: moment('2024-07-01').format('YYYY-MM-DD'), amount: '100', value:   0.10, total: 435.18 },
      // { id: 4, share: 'MXRF11', date: moment('2024-08-20').format('YYYY-MM-DD'), amount:  '50', value:   0.55, total:  22.45 },
      // { id: 5, share: 'MXRF11', date: moment('2024-08-01').format('YYYY-MM-DD'), amount: '100', value:   1.50, total:  58.56 },
      // { id: 6, share: 'MXRF11', date: moment('2024-08-23').format('YYYY-MM-DD'), amount:  '25', value: 560.00, total: 729.05 },
    ]  
  }
  
  constructor() {
    super(DividendCreateComponent, DividendModifyComponent)
  }

  async ngAfterViewInit(): Promise<void> {
    console.error('DividendsComponent -> ngAfterViewInit')

    console.log("listDividends: ", this.list.data)
  }
  
  override async evaluate(r: any):Promise<boolean> {
    console.error('DividendsComponent -> evaluate', r)

    const exists = this.list.data.filter((e:any) => {
      return moment.unix(e.date.seconds).format('YYYY-MM-DD') == moment(r.date).format('YYYY-MM-DD') && e.share == r.share && e.amount == r.amount
    })[0]

    return exists==undefined && r.amount > 0 && r.value > 0
  }
  
}
