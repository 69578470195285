import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collection, doc, setDoc, count, sum, average, getAggregateFromServer, getFirestore, deleteDoc, getDocs, where, query, documentId, getDoc, updateDoc, getDocFromCache, addDoc, orderBy, increment } from "firebase/firestore";
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  private companies!: BehaviorSubject<any[]>;

  constructor(
    private db: AngularFirestore
  ) {
    this.companies = new BehaviorSubject<any[]>([])
    this.selectAll()
  }

  public async selectAll():Promise<void> {
    // console.log('CompaniesService', 'selectAll')

    const companies:any[] = []

    const q = query(collection(getFirestore(), "Companies"))
    const allDocs = await getDocs(q)
    allDocs.docs.forEach((d:any, j:any, h:any) => {
      // console.log('Doc:', d.id, 'Side:', d.data().side, j, h)
      
      const doc = { ...d.data(), id: d.id }
      companies.push(doc)
    })
    
    this.companies.next(companies)
  }

  getAll(): Observable<any[]> {
    console.log('CompaniesService', 'getAll')

    return this.companies.asObservable();
  }

  addItem(e:any):void {
    console.log('CompaniesService', 'addItem', e)

    addDoc(collection(getFirestore(), "Companies"), {...e, created_on: moment().unix(), modified_on: moment().unix() })
    // this.companies.next([e, ...this.companies.value])
    this.selectAll()
  }

  delete(e:any): void{
    console.log('CompaniesService', 'delete', e.id)

    deleteDoc(doc(getFirestore(), "Companies", e.id))
    this.selectAll()
  }

  modify(e:any): void{
    console.log('CompaniesService', 'modify', e.id)

    if(e.amount != undefined)
      e.amount = e.amount.toString()

    updateDoc(doc(getFirestore(), "Companies", e.id), {...e, modified_on: moment().unix() })
    this.selectAll()
  }

}
