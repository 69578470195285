
<h2 mat-dialog-title>
    <mat-icon>subscriptions</mat-icon>
    Sign-Up - MFA
</h2>
<mat-dialog-content>
    <h3>{{ qrcodeTitle }}</h3>
    <img mat-card-image src="{{ data.qrcode }}" width="150px" height="150px">

    <h3>{{ qrcodeTitle }}</h3>
    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
</mat-dialog-content>
<mat-dialog-actions align="end">

    <div class="action">
        <div class="left">
            <button mat-icon-button (click)="paste()" class="primary">
                <mat-icon>content_paste</mat-icon>
              </button>
        </div>
        <div class="right">
            <button mat-raised-button color="primary" (click)="confirm()">Validate</button>
        </div>
    </div>    
    
</mat-dialog-actions>
<mat-card-footer>
    <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
</mat-card-footer>