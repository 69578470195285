  <form #createForm="ngForm" (ngSubmit)="onSubmit()">
      <h2 mat-dialog-title>Create Dividend</h2>

      <mat-dialog-content>

          <mat-form-field style="width: 100%;">
              <mat-label>Share</mat-label>
              <mat-select #share [(ngModel)]="dividend.share" name="dividend.share" required>
                <mat-option value="BCFF11">BCFF11</mat-option>
                <mat-option value="HCTR11">HCTR11</mat-option>
                <mat-option value="MXRF11">MXRF11</mat-option>
                <mat-option value="CXSE3">CXSE3</mat-option>
                <mat-option value="ITSA4">ITSA4</mat-option>
                <mat-option value="KLBN4">KLBN4</mat-option>
              </mat-select>
          </mat-form-field>
        
          <mat-divider></mat-divider>

          <mat-form-field>
              <mat-label>Date </mat-label>
              <input matInput [matDatepicker]="datepicker" (ngModelChange)="dividend.date=$event._d;" [ngModel]="dividend.date.seconds * 1000 | date: 'YYYY-MM-dd'" name="dividend.date" />
              <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #datepicker></mat-datepicker>
          </mat-form-field>
          &nbsp;&nbsp;
          <mat-form-field>
              <mat-label>Amount</mat-label>
              <input matInput type="number" (ngModelChange)="dividend.amount=$event;" [ngModel]="dividend.amount | number:'0.0'" name="dividend.amount" placeholder="0" required>
          </mat-form-field>
        
          <mat-divider></mat-divider>

          <mat-form-field>
              <mat-label>Value</mat-label>
              <input matInput type="number" step="0.01" (ngModelChange)="dividend.value=$event;" [ngModel]="dividend.value | number:'1.2-2'" name="dividend.value" placeholder="0.00" required>
          </mat-form-field>
          &nbsp;&nbsp;
          <mat-form-field>
              <mat-label>Total</mat-label>
              <input matInput type="number" [ngModel]="dividend.total | number:'1.2-2'" name="dividend.total" placeholder="0.00" readonly>
          </mat-form-field>
          
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-raised-button type="submit" [disabled]="createForm.invalid">Add</button>
          <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
        
  </form>