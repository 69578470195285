import { Injectable, OnDestroy, OnInit } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnInit, OnDestroy {

  user!: any;

  constructor() {
    this.user = localStorage.getItem('user')
    this.user = JSON.parse(this.user)
  }

  ngOnInit(): void {
    console.error('AccountService -> ngOnInit')
  }
  
  ngOnDestroy(): void {
    console.error('AccountService -> ngOnDestroy', this.user)
  }

  async getUserData() {
    console.error('AccountService -> getUserData', this.user)
    
    return this.user
  }



}
