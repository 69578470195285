import { Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild, input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dividend-modify',
  standalone: false,
  templateUrl: './modify.component.html',
  styleUrl: './modify.component.scss'
})
export class DividendModifyComponent implements OnInit, OnDestroy {

  @ViewChild('modifyForm', { static: true }) modifyForm: NgForm | undefined
  errorMessage!: string

  public dividend: any

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DividendModifyComponent>
  ) {
    console.error('DividendModifyComponent -> constructor', data)
    this.dividend = data
  }

  ngOnInit(): void {
    console.error('DividendModifyComponent -> ngOnInit')

    //Update total
    this.modifyForm?.valueChanges?.subscribe((input:any) => {
      // console.error('DividendCreateComponent -> modifyForm.valueChanges', input)
      
      if(input['dividend.amount'] != undefined && input['dividend.value'] != undefined)
        this.dividend.total = (parseInt(input['dividend.amount']) * parseFloat(input['dividend.value']))
    })
  }

  ngOnDestroy(): void {
    console.warn('DividendModifyComponent -> ngOnDestroy')
  }

  onSubmit(): void {
    console.error('DividendModifyComponent -> onSubmit', this.modifyForm?.value);

    if (this.modifyForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Dividend modified!',
          values: { ...this.dividend }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
