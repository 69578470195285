import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatDrawerMode } from '@angular/material/sidenav';
import { MatIcon } from '@angular/material/icon';
import { MatAccordion, MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';

import { SidenavService } from './sidenav.service';


@Component({
  selector: 'app-sidenav',
  standalone: false,
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.css'
})
export class SidenavComponent implements OnInit, OnDestroy {

  contentMargin: number = 170;
  sidenavMode: MatDrawerMode = 'side'
  sidenavShow: boolean = true
  sidenavClass: string = 'sidenav'
  collapsed: boolean = false


  constructor(
    private sidenavService: SidenavService
  ) { }

  ngOnInit(): void {
    console.error('SidenavComponent -> ngOnInit')

    //sidenav - Status
    this.sidenavService.sidenavStatus().subscribe( (b) => {
      this.sidenavShow = b
    })

    //Sidenav - Mode
    this.sidenavService.sidenavMode().subscribe( (s) => { 
      console.log('SettingsComponent -> sidenavMode:subscribe', s)
      this.sidenavMode = s as MatDrawerMode;
      if (s === 'side' || s === 'push') {
        this.sidenavService.sidenavStatusChange(true);
      }
     })

    //Sidenav - Size
    this.sidenavService.sidenavSize().subscribe( (s) => { 
      console.log('SettingsComponent -> sidenavSize:subscribe', s)
      this.sidenavClass = (s === 'sidenav') ? 'sidenav' : 'collaped'
      this.collapsed = (s === 'sidenav') ? false : true
      this.contentMargin = (this.collapsed) ? 55 : 170
     })
  }

  ngOnDestroy(): void {
    console.warn('SidenavComponent -> ngOnDestroy')
  }

  collapseToggle(): void {
    console.error('SidenavComponent -> collapseToggle')

    this.sidenavService.sidenavSizeChange((this.sidenavClass === 'sidenav') ? 'collaped' : 'sidenav')
  }

}
  

