import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collection, doc, setDoc, count, sum, average, getAggregateFromServer, getFirestore, deleteDoc, getDocs, where, query, documentId, getDoc, updateDoc, getDocFromCache, addDoc, orderBy, increment } from "firebase/firestore";
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DividendsService {

  private dividends!: BehaviorSubject<any[]>;

  constructor(
    private db: AngularFirestore
  ) {
    this.dividends = new BehaviorSubject<any[]>([])
    this.selectAll()
  }

  public async selectAll(): Promise<void> {
    // console.log('DividendsService', 'selectAll')

    const dividends:any[] = []

    const q = query(collection(getFirestore(), "Dividends"))
    const allDocs = await getDocs(q)
    allDocs.docs.forEach((d:any, j:any, h:any) => {
      // console.log('Doc:', d.id, 'Side:', d.data().side, j, h)
      
      const doc = { ...d.data(), id: d.id }
      dividends.push(doc)
    })

    this.dividends.next(dividends)
  }

  getAll(): Observable<any[]> {
    console.log('DividendsService', 'getAll')

    return this.dividends.asObservable();
  }

  addItem(e:any):void {
    console.log('DividendsService', 'addItem', e)

    addDoc(collection(getFirestore(), "Dividends"), { ...e, amount: e.amount.toString(), created_on: moment().unix(), modified_on: moment().unix() })
    // this.dividends.next([e, ...this.dividends.value])
    this.selectAll()
  }

  delete(e:any): void{
    console.log('DividendsService', 'delete', e.id)

    deleteDoc(doc(getFirestore(), "Dividends", e.id))
    this.selectAll()
  }

  modify(e:any): void{
    console.log('DividendsService', 'modify', e.id)

    updateDoc(doc(getFirestore(), "Dividends", e.id), { ...e, amount: e.amount.toString(), modified_on: moment().unix() })
    this.selectAll()
  }

}
