import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { Secret, TOTP } from 'otpauth';
import { toDataURL } from 'qrcode';

import { environment } from '../../../../environments/environment';
import { SignUpService } from './sign-up.service';
import { TotpSetupComponent } from './totp-setup/totp-setup.component';


@Component({
  selector: 'app-sign-up',
  standalone: false,
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.css'
})
export class SignUpComponent implements OnInit, OnDestroy {

  name: string = '';
  email: string = '';
  password: string = '';
  errorMessage: string = '';
  qrcode: string = '';

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _signUpService: SignUpService
  ) { }

  ngOnInit(): void {
    console.error('SignUpComponent -> ngOnInit')
  }

  ngOnDestroy(): void {
    console.warn('SignUpComponent -> ngOnDestroy');
  }

  async onSubmit() {
    console.error('SignUpComponent -> onSubmit');

    const secretHex = Array.from(this.email, char => char.charCodeAt(0).toString(16).padStart(2, '0')).join('')

    const token = new TOTP({
      issuer: this.name,
      label: environment.token.label,
      algorithm: environment.token.algorithm,
      digits: environment.token.digits,
      period: environment.token.period,
      secret: Secret.fromHex(secretHex),
    }).toString();

    // NFRWC4TPIBUW45DFOJWGSZ3BMRXXGLTOMV2A
    console.log(token)
    
    await this._signUpService.signUp(this.name, this.email, this.password, token)
      .then((data: any) => {
        console.log("data: ", data);

        if (data.errorCode != undefined)
          throw new Error(data.errorMessage)

        toDataURL(token)
          .then((s: string) => {
            this.qrcode = s;
            this.openDialog()
          })
      })
      .catch((error) => {
        this.errorMessage = error.code + " <-> " + error.message
      })
  }

  openDialog() {
    console.error('SignUpComponent -> openDialog', 'TotpSetupComponent');

    let dialogRef = this._dialog.open(TotpSetupComponent, {
      disableClose: true,
      data: {
        qrcode: this.qrcode,
        email: this.email
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      console.error('SignUpComponent -> dialogRef.afterClosed()', 'TotpSetupComponent');

      this.errorMessage = res.data.token.toString()
      console.log(res.data)
      //TODO: Validar se o token é "OK"
      this.errorMessage = res.data.token;

      localStorage.setItem('token', "OK");

      this._router.navigateByUrl('/home');  // open welcome component
    })
  }
}
