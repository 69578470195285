import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Error404Component } from '../shared/errors/errors.module';

import { AppGuard } from '../../app.guard';
import { AccountGuard } from './account.guard';
import { AccountComponent } from './account.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SettingsComponent } from './settings/settings.component';

export const routes: Routes = [
  {
    path: 'account',
    data: { title: 'Account' },
    children: [
      { path: '', component: AccountComponent, canActivate: [AppGuard] },
      { path: 'sign-in', component: SignInComponent, canActivate: [AccountGuard] },
      { path: 'sign-up', component: SignUpComponent, canActivate: [AccountGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AppGuard] },

      { path: '**', component: Error404Component }, // Error 404
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }