import { AfterViewInit, Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import moment from 'moment';

import { InvestimentsBase } from '../investiments.base';
import { ReitsService } from './reits.service';
import { ReitCreateComponent } from './create/create.component';
import { ReitModifyComponent } from './modify/modify.component';

@Component({
  selector: 'app-reits',
  standalone: false,
  templateUrl: './reits.component.html',
  styleUrl: './reits.component.scss'
})
export class ReitsComponent extends InvestimentsBase<ReitCreateComponent, ReitModifyComponent> implements AfterViewInit {

  override service = inject(ReitsService)

  override list : any = {
    columns: [
      'share', 'side', 'amount', 'price', 'total', 'date', 'action'
    ],
    data: [
      // { id: 1, share: 'BCFF11', date: moment('2024-07-01').format('YYYY-MM-DD'), side:  'BUY', amount: '100', price:  0.50, total:  60.10, attach: '' },
      // { id: 2, share: 'HCTR11', date: moment('2024-07-01').format('YYYY-MM-DD'), side:  'BUY', amount:  '25', price: 30.00, total: 760.20, attach: '' },
      // { id: 3, share: 'MXRF11', date: moment('2024-07-01').format('YYYY-MM-DD'), side:  'BUY', amount: '100', price:  4.25, total: 435.18, attach: 5  },
      // { id: 4, share: 'MXRF11', date: moment('2024-08-20').format('YYYY-MM-DD'), side: 'SELL', amount:  '50', price:  0.65, total:  22.45, attach: '' },
      // { id: 5, share: 'MXRF11', date: moment('2024-08-01').format('YYYY-MM-DD'), side:  'BUY', amount: '100', price:  0.48, total:  58.56, attach: 2  },
      // { id: 6, share: 'MXRF11', date: moment('2024-08-23').format('YYYY-MM-DD'), side: 'SELL', amount:  '25', price: 29.50, total: 729.05, attach: 1  },
    ]  
  }
  
  constructor() {
    super(ReitCreateComponent, ReitModifyComponent)
  }

  async ngAfterViewInit(): Promise<void> {
    console.error('ReitsComponent -> ngAfterViewInit')

    console.log("listReits: ", this.list.data)
  }

  override async evaluate(r: any, mode:string='add'):Promise<boolean> {
    // console.error('ReitsComponent -> evaluate', r)
    
    console.error('ReitsComponent -> evaluate', r.share)

    const exists = this.list.data.filter((i: any)=>{ return i.id == r.id })[0]
    console.error('ReitsComponent -> evaluate', 'exists', exists?.id == r.id)

    var list:any[] = [...this.list.data]
    if(exists==undefined)
      list.push(r)
    if(exists!=undefined)
      list = [...this.list.data.filter((i: any)=>{ return i.id != r.id }), r]

    let amount:number = 0;
    await list.forEach((e:any) => {      
      if(e.share == r.share) {
        if(e.side == 'BUY')
          amount = amount + parseInt(e.amount)
        
        if(e.side == 'SELL')
          amount = amount - parseInt(e.amount)
      }
    })

    console.warn('ReitsComponent -> evaluate', r.share, amount, amount >= 0)
    return amount > 0 && r.amount > 0 && r.price > 0
  }
  
}
