<mat-toolbar>
  <mat-toolbar-row>
    <div class="toolbar">
        <!-- <button mat-icon-button (click)="sidenavToggle()" matBadge="4" matBadgeOverlap="true"> -->
        <button mat-icon-button (click)="sidenavToggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <div class="right">
          <button mat-icon-button [matMenuTriggerFor]="menuSettings" class="rotate">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
      <div class="right">
        <button mat-icon-button (click)="themeToogle()">
          <mat-icon #themeIcon></mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menuSettings="matMenu">
  <button mat-menu-item routerLink="/account">
    <mat-icon>account_circle</mat-icon>
    <span>Account</span>
  </button>
  <button mat-menu-item routerLink="/videocall" disabled>
    <mat-icon>videocam</mat-icon>
    <span>Conference</span>
  </button>
  <button mat-menu-item (click)="notificationToogle()">
    <mat-icon #notificationIcon></mat-icon>
    <span #iconLabel></span>
  </button>
  <button mat-menu-item routerLink="/account/settings">
    <mat-icon>tune</mat-icon>
    <span>Settings</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>