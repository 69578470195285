import { Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild, input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'company-modify',
  standalone: false,
  templateUrl: './modify.component.html',
  styleUrl: './modify.component.css'
})
export class CompanyModifyComponent implements OnInit, OnDestroy {

  title!: string

  @ViewChild('modifyForm', { static: true }) modifyForm!: NgForm
  errorMessage!: string

  company:any

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CompanyModifyComponent>
  ) {
    console.error('ModifyComponent -> constructor', data)

    this.company = data
  }

  ngOnInit(): void {
    console.error('ModifyComponent -> ngOnInit')
  }

  ngOnDestroy(): void {
    console.warn('ModifyComponent -> ngOnDestroy')
  }

  onFileSelected(event: Event): void {
    console.error('ModifyComponent -> onFileSelected')

    const input = event.target as HTMLInputElement
    if (input && input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader()
      reader.onload = () => {
        this.company.img = reader.result
      };
      reader.readAsDataURL(file)
      // reader.readAsArrayBuffer(file);
    }
  }

  onSubmit(): void {
    console.error('ModifyComponent -> onSubmit', this.modifyForm)

    if (this.modifyForm)
      this.dialogRef.close({
        data: {
          response: 'Submitted modify form!',
          // values: { ...this.modifyForm.value, image: this.company.img }
          values: { ...this.company }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
