import { AfterViewInit, Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import moment from 'moment';

import { InvestimentsBase } from '../investiments.base';
import { SharesService } from './shares.service';
import { ShareCreateComponent } from './create/create.component';
import { ShareModifyComponent } from './modify/modify.component';

@Component({
  selector: 'app-shares',
  standalone: false,
  templateUrl: './shares.component.html',
  styleUrl: './shares.component.scss'
})
export class SharesComponent extends InvestimentsBase<ShareCreateComponent, ShareModifyComponent> implements AfterViewInit {

  override service = inject(SharesService)

  override list : any = {
    columns: [
      'company', 'code', 'site', 'action'
    ],
    data: [
      // { id: 1, company: 'CXSE', code: 'CXSE3', site: 'www.caixa.gov.br' },
      // { id: 2, company: 'ITSA', code: 'ITSA4', site:   'www.Itausa.com' },
      // { id: 3, company: 'KLBN', code: 'KLBN4', site:    'Klabin.com.br' },
      // { id: 3, company: 'ACME', code: 'ACME1', site:         'acme.com' },
    ]  
  }
  
  constructor() {
    super(ShareCreateComponent, ShareModifyComponent)
  }

  async ngAfterViewInit(): Promise<void> {
    console.error('SharesComponent -> ngAfterViewInit')

    console.log("listShares: ", this.list.data)
  }

  // override async evaluate(r: any):Promise<boolean> {
  //   console.error('SharesComponent -> evaluate', r)

  //   return await true
  // }
  
}
