import { Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild, input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'reit-modify',
  standalone: false,
  templateUrl: './modify.component.html',
  styleUrl: './modify.component.scss'
})
export class ReitModifyComponent implements OnInit, OnDestroy {

  @ViewChild('modifyForm', { static: true }) modifyForm: NgForm | undefined
  errorMessage!: string

  public reit: any

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReitModifyComponent>
  ) {
    console.error('ReitModifyComponent -> constructor', data)
    this.reit = data
  }

  ngOnInit(): void {
    console.error('ReitModifyComponent -> ngOnInit')

    //Update total
    this.modifyForm?.valueChanges?.subscribe((input:any) => {
      // console.error('ReitCreateComponent -> modifyForm.valueChanges', input)
      
      if(input['reit.amount'] != undefined && input['reit.price'] != undefined)
        this.reit.total = (parseInt(input['reit.amount']) * parseFloat(input['reit.price']))
    })
  }

  ngOnDestroy(): void {
    console.warn('ReitModifyComponent -> ngOnDestroy')
  }

  onSubmit(): void {
    console.error('ReitModifyComponent -> onSubmit', this.modifyForm?.value);

    if (this.modifyForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Reit modified!',
          values: { ...this.reit }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
