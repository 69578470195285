import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatOptionModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule, MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { InvestmentsRoutingModule } from './investments-routing.module';
import { InvestmentsComponent } from './investments.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyCreateComponent } from './companies/create/create.component';
import { CompanyModifyComponent } from './companies/modify/modify.component';
import { StocksComponent } from './stocks/stocks.component';
import { StockCreateComponent } from './stocks/create/create.component';
import { StockModifyComponent } from './stocks/modify/modify.component';
import { ReitsComponent } from './reits/reits.component';
import { ReitCreateComponent } from './reits/create/create.component';
import { ReitModifyComponent } from './reits/modify/modify.component';
import { DividendsComponent } from './dividends/dividends.component';
import { DividendCreateComponent } from './dividends/create/create.component';
import { DividendModifyComponent } from './dividends/modify/modify.component';
import { SharesComponent } from './shares/shares.component';
import { ShareCreateComponent } from './shares/create/create.component';
import { ShareModifyComponent } from './shares/modify/modify.component';
import { FixedComponent } from './fixed/fixed.component';
import { FixedCreateComponent } from './fixed/create/create.component';
import { FixedModifyComponent } from './fixed/modify/modify.component';



@NgModule({
  declarations: [
    InvestmentsComponent,
    CompaniesComponent,
    CompanyCreateComponent,
    CompanyModifyComponent,
    SharesComponent,
    ShareCreateComponent,
    ShareModifyComponent,    
    StocksComponent,
    StockCreateComponent,
    StockModifyComponent,
    ReitsComponent,
    ReitCreateComponent,
    ReitModifyComponent,
    DividendsComponent,
    DividendCreateComponent,
    DividendModifyComponent,
    FixedComponent,
    FixedCreateComponent,
    FixedModifyComponent,
  ],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    
    InvestmentsRoutingModule,

    MatCardModule, 
    MatInputModule, 
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDividerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatListModule,
    MatOptionModule,
    MatRadioModule,
    MatRadioButton,
    MatRadioGroup,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule, 
    MatLabel,
    MatBadgeModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule
    
  ],
  providers:[
    provideNativeDateAdapter()
  ],
  exports:[
  ]
})
export class InvestmentsModule { }

