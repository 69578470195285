import { AfterViewInit, Component, Inject, ModelFunction, Optional, ViewChild, model } from '@angular/core';
import moment from 'moment';

import { ShareModel } from '../shares.model';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShareModifyComponent } from '../modify/modify.component';
import { randomBytes } from 'crypto';


@Component({
  selector: 'share-create',
  standalone: false,
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class ShareCreateComponent implements AfterViewInit {
  
  public share: any

  @ViewChild('createForm', { static: true }) createForm: NgForm | undefined
  public errorMessage: any
  
  constructor(
    private dialogRef: MatDialogRef<ShareModifyComponent>,
  ){
    this.share = {
      id: Math.random().toFixed(3),
      company: '',
      code: '',
      site: ''
    }
  }

  ngAfterViewInit(): void {
    console.error('ShareCreateComponent -> ngAfterViewInit')

  }

  async onSubmit() {
    console.error('ShareCreateComponent -> onSubmit', this.share, this.createForm?.valid)

    if (this.createForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Share created!',
          values: { ...this.share }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
