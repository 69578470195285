  <form #createForm="ngForm" (ngSubmit)="onSubmit()">
      <h2 mat-dialog-title>Create Reit</h2>

      <mat-dialog-content>

        <section>
          <mat-button-toggle-group #side [(ngModel)]="reit.side" [value]="reit.side" name="reit.side" hideSingleSelectionIndicator="true" style="width: 99%;">        
              <mat-button-toggle value="BUY" style="width: 50%;">Buy</mat-button-toggle>
              <mat-button-toggle value="SELL" style="width: 50%;">Sell</mat-button-toggle>
          </mat-button-toggle-group>
        </section>

        <mat-divider></mat-divider>
        <mat-divider></mat-divider>
        <mat-divider></mat-divider>

          <mat-form-field style="width: 100%;">
              <mat-label>Share</mat-label>
              <mat-select #share [(ngModel)]="reit.share" name="reit.share" required>
                <mat-option value="BCFF11">BCFF11</mat-option>
                <mat-option value="HCTR11">HCTR11</mat-option>
                <mat-option value="MXRF11">MXRF11</mat-option>
              </mat-select>
          </mat-form-field>
          
          <mat-divider></mat-divider>

          <mat-form-field>
              <mat-label>Date Operation</mat-label>
              <input matInput [matDatepicker]="datepicker" (ngModelChange)="reit.date=$event._d;" [ngModel]="reit.date.seconds * 1000 | date: 'YYYY-MM-dd'" name="reit.date" />
              <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #datepicker></mat-datepicker>
          </mat-form-field>
          &nbsp;&nbsp;
          <mat-form-field>
              <mat-label>Amount</mat-label>
              <input matInput type="number" (ngModelChange)="reit.amount=$event;" [ngModel]="reit.amount | number:'1.2-2'" name="reit.amount" placeholder="0" required>
          </mat-form-field>

          <mat-divider></mat-divider>

          <mat-form-field>
              <mat-label>Price</mat-label>
              <input matInput type="number" step="0.01" (ngModelChange)="reit.price=$event;" [ngModel]="reit.price | number:'1.2-2'" name="reit.price" placeholder="0.00" required>
          </mat-form-field>
          &nbsp;&nbsp;
          <mat-form-field>
              <mat-label>Total</mat-label>
              <input matInput type="number" [ngModel]="reit.total | number:'1.2-2'" name="reit.total" placeholder="0.00" readonly>
          </mat-form-field>
          
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-raised-button type="submit" [disabled]="createForm.invalid">Add</button>
          <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
        </mat-dialog-actions>

      <!-- <mat-card-footer>
        <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
      </mat-card-footer> -->
  </form>