<form #modifyForm="ngForm" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Modity Dividend</h2>

      <mat-dialog-content>

        <mat-form-field style="width: 100%;">
            <mat-label>Share</mat-label>
            <input matInput type="text" [(ngModel)]="dividend.share" name="dividend.share" disabled>
        </mat-form-field>    
        
        <mat-divider></mat-divider>

        <mat-form-field>
            <mat-label>Date Operation</mat-label>
            <input matInput [matDatepicker]="datepicker" (ngModelChange)="dividend.date=$event._d;" [ngModel]="dividend.date.seconds * 1000 | date: 'YYYY-MM-dd'" name="dividend.date" disabled/>
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker>
                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button matDatepickerApply>Apply</button>
                </mat-datepicker-actions>
            </mat-datepicker>
        </mat-form-field>
        &nbsp;&nbsp;
        <mat-form-field>
            <mat-label>Amount</mat-label>
            <input matInput type="number" (ngModelChange)="dividend.amount=$event;" [ngModel]="dividend.amount | number:'0.0'" name="dividend.amount" placeholder="0" required>
        </mat-form-field>
        
        <mat-divider></mat-divider>

        <mat-form-field>
            <mat-label>Value</mat-label>
            <input matInput type="number" step="0.01" (ngModelChange)="dividend.value=$event;" [ngModel]="dividend.value | number:'1.2-2'" name="dividend.value" placeholder="0.00" required>
        </mat-form-field>
        &nbsp;&nbsp;
        <mat-form-field>
            <mat-label>Total</mat-label>
            <input matInput type="number" [ngModel]="dividend.total | number:'1.2-2'" name="dividend.total" placeholder="0.00" readonly>
        </mat-form-field>        
    
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button type="submit" [disabled]="modifyForm.invalid">Save</button>
        <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
      </mat-dialog-actions>

    <!-- <mat-card-footer>
      <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
    </mat-card-footer> -->

</form>
