<form #modifyForm="ngForm" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Modify Share</h2>

      <mat-dialog-content>

        <mat-form-field>
            <mat-label>Company</mat-label>
            <mat-select #company [ngModel]="share.company" name="share.company" required>
                <mat-option value="CXSE">CXSE</mat-option>
                <mat-option value="ITSA">ITSA</mat-option>
                <mat-option value="KLBN">KLBN</mat-option>
                <mat-option value="ACME">ACME</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field>
            <mat-label>Code</mat-label>
            <input matInput type="text" [(ngModel)]="share.code" name="share.code" required>
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field>
          <mat-label>Site</mat-label>
          <input matInput type="text" [(ngModel)]="share.site" name="share.site" required>
        </mat-form-field>
    
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button type="submit" [disabled]="modifyForm.invalid">Save</button>
        <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
      </mat-dialog-actions>

    <!-- <mat-card-footer>
      <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
    </mat-card-footer> -->

</form>
