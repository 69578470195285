import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collection, doc, setDoc, count, sum, average, getAggregateFromServer, getFirestore, deleteDoc, getDocs, where, query, documentId, getDoc, updateDoc, getDocFromCache, addDoc, orderBy, increment } from "firebase/firestore";
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StocksService {

  private stocks!: BehaviorSubject<any[]>;

  constructor(
    private db: AngularFirestore,
  ) {
    this.stocks = new BehaviorSubject<any[]>([])
    this.selectAll()
  }

  public async selectAll(): Promise<void> {
    // console.log('StocksService', 'selectAll')

    const stocks:any[] = []

    const q = query(collection(getFirestore(), "Stocks"))
    const allDocs = await getDocs(q)
    allDocs.docs.forEach((d:any, j:any, h:any) => {
      // console.log('Doc:', d.id, 'Side:', d.data().side, j, h)
      
      const doc = { ...d.data(), id: d.id }
      stocks.push(doc)
    })
    
    this.stocks.next(stocks)
  }

  getAll(): Observable<any[]> {
    console.log('CompaniesService', 'getAll')

    return this.stocks.asObservable();
  }

  addItem(e:any):void {
    console.log('StocksService', 'addItem', e)

    if(e.amount != undefined)
      e.amount = e.amount.toString()

    // if(e.date != undefined)
    //   e.date = new Date(e.date.seconds).getSeconds()

    addDoc(collection(getFirestore(), "Stocks"), { ...e, amount: e.amount.toString(), created_on: moment().unix(), modified_on: moment().unix() })

    // this.stocks.next([e, ...this.stocks.value])
    this.selectAll()
  }

  delete(e:any): void{
    console.log('StocksService', 'delete', e.id)

    deleteDoc(doc(getFirestore(), "Stocks", e.id))
    this.selectAll()
  }

  modify(e:any): void{
    console.log('StocksService', 'modify', e.id)

    updateDoc(doc(getFirestore(), "Stocks", e.id), { ...e, amount: e.amount.toString(), modified_on: moment().unix() })
    this.selectAll()
  }

  async updateAttach(e: any):Promise<void> {
    console.log('StocksService', 'updateAttach', e.id)
    
    await updateDoc(doc(getFirestore(), "Stocks", e.id), {
      attach: increment(1)
    })
    .then(() => {
      console.log('StocksService', 'updateAttach', e.id, 'UPDATED')
    })    
  }

}
