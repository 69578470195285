import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collection, doc, setDoc, count, sum, average, getAggregateFromServer, getFirestore, deleteDoc, getDocs, where, query, documentId, getDoc, updateDoc, getDocFromCache, addDoc, orderBy, increment } from "firebase/firestore";
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharesService {

  private shares!: BehaviorSubject<any[]>;

  constructor(
    private db: AngularFirestore,
  ) {
    this.shares = new BehaviorSubject<any[]>([])
    this.selectAll()
  }

  public async selectAll(): Promise<void> {
    // console.log('SharesService', 'selectAll')

    const shares:any[] = []

    const q = query(collection(getFirestore(), "Shares"))
    const allDocs = await getDocs(q)
    allDocs.docs.forEach((d:any, j:any, h:any) => {
      console.log('Doc:', d.id, 'Side:', d.data().side, j)
      const doc = { ...d.data(), id: d.id }
      shares.push(doc)
    })

    this.shares.next(shares)
  }

  getAll(): Observable<any[]> {
    console.log('SharesService', 'getAll')

    return this.shares.asObservable();
  }
  
  addItem(e:any):void {
    console.log('SharesService', 'addItem', e)

    addDoc(collection(getFirestore(), "Shares"), { ...e, created_on: moment().unix(), modified_on: moment().unix() })
    // this.shares.next([e, ...this.shares.value])
    this.selectAll()
  }

  delete(e:any): void{
    console.log('SharesService', 'delete', e.id)

    deleteDoc(doc(getFirestore(), "Shares", e.id))
    this.selectAll()
  }

  modify(e:any): void{
    console.log('SharesService', 'modify', e.id)

    updateDoc(doc(getFirestore(), "Shares", e.id), { ...e, modified_on: moment().unix() })
    this.selectAll()
  }

}
