import { AfterViewInit, Component, Inject, ModelFunction, Optional, ViewChild, model } from '@angular/core';
import moment from 'moment';

import { ReitModel } from '../reit.model';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReitModifyComponent } from '../modify/modify.component';


@Component({
  selector: 'reit-create',
  standalone: false,
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class ReitCreateComponent implements AfterViewInit {
  
  public reit: any

  @ViewChild('createForm', { static: true }) createForm: NgForm | undefined
  public errorMessage: any
  
  constructor(
    private dialogRef: MatDialogRef<ReitModifyComponent>,
  ){
    this.reit = {
      id: Math.random().toFixed(3),
      share: '',
      date: new Date(),
      side: '',
      amount: 0,
      price: 0.00,
      tax: 0.00,
      total: 0.00
    }
  }

  ngAfterViewInit(): void {
    console.error('ReitCreateComponent -> ngAfterViewInit')

    //Update total
    this.createForm?.valueChanges?.subscribe((input:any) => {
      // console.error('ReitCreateComponent -> createForm.valueChanges', input)
      
      if(input['reit.amount'] != undefined && input['reit.price'] != undefined)
        this.reit.total = (parseInt(input['reit.amount']) * parseFloat(input['reit.price']))
    })
  }

  async onSubmit() {
    console.error('ReitCreateComponent -> onSubmit', this.reit, this.createForm?.valid)

    if (this.createForm?.valid)
      this.dialogRef.close({
        data: {
          response: 'Reit created!',
          values: { ...this.reit }
        }
      })
    else
      this.errorMessage = "Errorrrrr"
  }
}
