import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatSidenav } from '@angular/material/sidenav';
import { MatIcon } from '@angular/material/icon';

import { ThemeService } from '../../account/settings/services/theme.service';
import { NotificationService } from '../../account/settings/services/notification.service';
import { SidenavService } from '../sidenav/sidenav.service';
import { SignInService } from '../../account/sign-in/sign-in.service';

@Component({
  selector: 'app-header',
  standalone: false,
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() inputSideNav!: MatSidenav  
  @ViewChild('iconLabel') iconLabel!: ElementRef;
  @ViewChild('notificationIcon') notificationIcon!: MatIcon;
  @ViewChild('themeIcon') themeIcon!: MatIcon;

  signedIn: boolean = false;

  sidenavStatus$!: boolean

  constructor(
    // private authService: AuthService,
    private router: Router, 
    private themeService: ThemeService,
    private notificationService: NotificationService,
    private sidenavService: SidenavService, 
    private signInService: SignInService
  ) { }

  ngOnInit(): void {
    console.error('HeaderComponent -> ngOnInit');

    // signedIn: boolean = this.authService.isLogged()
    this.signedIn = true;

    //Sidenav
    this.sidenavService.sidenavStatus().subscribe( (b) => { 
      console.log('HeaderComponent -> sidenavStatus:subscribe', b)

      this.sidenavStatus$ = b
      if (this.sidenavStatus$){
        this.inputSideNav.open()
       } else {
        this.inputSideNav.close()
      }
     })
  }

  ngAfterViewInit(): void {
    console.warn('HeaderComponent -> ngAfterViewInit');

    //Theme
    this.themeService.themeStatus().subscribe((b) => { 
      console.log('HeaderComponent -> themeService:subscribe', b)

      this.themeIcon._elementRef.nativeElement.innerHTML = !b ? 'sunny' : 'nightlight'
      
        const body = document.getElementsByTagName('body')[0].classList;
        console.log(body);
        
        if (!b){
          body.add('dark');
        } else {
          body.remove('dark');
        }
        console.log(body);
    })

    //Notification
    this.notificationService.notificationStatus().subscribe((b) => { 
      console.log('HeaderComponent -> notificationStatus:subscribe', b)

      if (b){
        this.notificationIcon._elementRef.nativeElement.innerHTML = 'notifications_active'
        this.iconLabel.nativeElement.innerHTML = 'Turn Off'
      } else {
        this.notificationIcon._elementRef.nativeElement.innerHTML = 'notifications_off'
        this.iconLabel.nativeElement.innerHTML = 'Turn On'
      }
    })
  }

  ngOnDestroy(): void {
    console.warn('HeaderComponent -> ngOnDestroy')
  }

  sidenavToggle(): void {
    console.info('HeaderComponent -> sidenavToggle')

    this.sidenavService.sidenavStatusChange(!this.inputSideNav.opened)
  }

  themeToogle(): void {
    console.info('HeaderComponent -> themeToogle')

    this.themeService.themeToogle()
  }

  notificationToogle(): void {
    console.info('HeaderComponent -> notificationToogle')

    this.notificationService.notificationToogle()
  }

  logout(): void {
    console.info('HeaderComponent -> logout');

    this.signedIn = false
    this.signInService.signOut()
    this.router.navigate(['/account/sign-in'])
  }
}
