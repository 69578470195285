  <form #createForm="ngForm" (ngSubmit)="onSubmit()">
      <h2 mat-dialog-title>Create Share</h2>

      <mat-dialog-content>

          <mat-form-field>
              <mat-label>Company</mat-label>
              <mat-select #company [(ngModel)]="share.company" name="share.company" required>
                  <mat-option value="CXSE">Caixa Seguridade</mat-option>
                  <mat-option value="ITSA">ITAUSA INVESTIMENTOS ITAU S.A.</mat-option>
                  <mat-option value="KLBN">KLABIN S.A.</mat-option>
                  <mat-option value="ACME">ACME</mat-option>
              </mat-select>
          </mat-form-field>

        <mat-divider></mat-divider>

          <mat-form-field>
              <mat-label>Code</mat-label>
              <input matInput type="text" [(ngModel)]="share.code" name="share.code" placeholder="XPTO3" required>
          </mat-form-field>

          <mat-divider></mat-divider>
          
          <mat-form-field>
              <mat-label>Site</mat-label>
              <input matInput type="text" [(ngModel)]="share.site" name="share.site" placeholder="www.xpto.com" required>
          </mat-form-field>
          
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-raised-button type="submit" [disabled]="createForm.invalid">Add</button>
          <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
        </mat-dialog-actions>

      <!-- <mat-card-footer>
        <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
      </mat-card-footer> -->
  </form>