import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '../environments/environment';

export const AppGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  // return environment.ENVIRONMENT == 'DEV';

  if (localStorage.getItem('token') != "OK") {
    router.navigate(['/account/sign-in']);
    return false;
  } 

  return true
};

